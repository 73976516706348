import { type PropsWithChildren } from 'react'

import { Box, HStack, Icon, Text } from '@chakra-ui/react'
import { BiSolidDollarCircle } from 'react-icons/bi'

export type ActionableWrapperProps = {
  actionable: boolean
} & PropsWithChildren

export const ActionableWrapper = ({
  actionable,
  children,
}: ActionableWrapperProps): JSX.Element => {
  if (!actionable) {
    return <>{children}</>
  }
  return (
    <Box
      bg="red.100"
      borderColor="red.200"
      borderRadius="calc(0.5rem - 1px)"
      borderWidth="1px"
      w="full"
    >
      <HStack px="1rem" py="0.5rem" w="full">
        <Icon
          as={BiSolidDollarCircle}
          color="interaction.main.default"
          fontSize="1rem"
        />
        <Text color="interaction.main.default" textStyle="subhead-2">
          Pay to confirm
        </Text>
      </HStack>
      <Box my="-1px">{children}</Box>
    </Box>
  )
}
