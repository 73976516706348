import { useMemo } from 'react'

import { BiCalendar, BiMap, BiUser } from 'react-icons/bi'

import { PROGRAMMES_ROUTES } from '@activesg/common/routes/member'
import { dayjs, mergeTimeslots } from '@activesg/common/utilities'
import {
  getInitialState,
  ProgrammePaymentState,
} from '@activesg/server/services/programme/programme.state.js'

import { BALLOT_CONFIRMATION, BOOKING_CONFIRMATION } from '~/constants/routes'
import { trpc, type RouterOutput } from '~/utils/trpc'

import {
  BallotBadge,
  FacilityOrProgrammeBookingBadge,
  type BookingCardProps,
} from './BookingCard'
import { BookingCardDetailRowLayout } from './BookingCard/BookingCardDetailRowLayout'

export type MappedBookingItemShape = BookingCardProps & {
  /** Used to determine sort order of items after merging `FacilityBooking` and `ProgrammeBooking` */
  earliestScheduledDate: Date
  /** Used as unique key when rendering `BookingCard` */
  bookingId: string
}

const mapProgrammeBookingToCardProps = (
  pb: RouterOutput['programme']['programmeBooking']['list']['upcoming'][number],
): MappedBookingItemShape => {
  if (!pb.programme.sessions[0]) {
    throw new Error('Programme without sessions')
  }

  const startDates = pb.programme.sessions
    .map((session) => dayjs(session.startDateTime).tz())
    .sort((a, b) => a.valueOf() - b.valueOf())

  const upcomingSessions = pb.programme.sessions
    .filter((s) => new Date(s.endDateTime).valueOf() > new Date().valueOf())
    .sort(
      (a, b) =>
        new Date(a.startDateTime).valueOf() -
        new Date(b.startDateTime).valueOf(),
    )

  let dateLabel = dayjs(pb.programme.sessions[0].startDateTime)
    .tz()
    .format('ddd, D MMM, h:mm a')

  // just show next upcoming session date
  if (upcomingSessions[0] !== undefined) {
    dateLabel = dayjs(upcomingSessions[0].startDateTime)
      .tz()
      .format('ddd, D MMM, h:mm a')
    // no more upcoming sessions, just show full range of sessions for date label
  } else if (pb.programme.sessions.length > 1) {
    const earliestStartDate = startDates.sort(
      (a, b) => a.valueOf() - b.valueOf(),
    )[0]

    const endDates = pb.programme.sessions.map((session) =>
      dayjs(session.endDateTime).tz(),
    )

    const latestEndDate = endDates.sort((a, b) => b.valueOf() - a.valueOf())[0]

    // should never happen with length guard clause
    if (earliestStartDate === undefined || latestEndDate === undefined) {
      throw new Error(
        'Unable to retrieve earliest start date and latest end date',
      )
    }

    dateLabel = `${earliestStartDate.format('ddd, D MMM')} - ${latestEndDate.format('ddd, D MMM')}`
  }

  // this should never happen
  if (pb.programme.venue === null) {
    throw new Error('Programme without venue')
  }

  // this should never happen
  if (startDates[0] === undefined) {
    throw new Error('No start date')
  }

  const programmePaymentStatus = getInitialState({
    paymentStatus: pb.payment.status,
    programmeBookingStatus: pb.status,
  })

  return {
    bookingId: pb.id,
    title: pb.programme.title,
    details: [
      <BookingCardDetailRowLayout
        key={pb.attendee.id}
        icon={BiUser}
        label={pb.attendee.name}
      />,
      <BookingCardDetailRowLayout
        key={pb.programme.venue.name}
        icon={BiMap}
        label={pb.programme.venue.name}
      />,
      <BookingCardDetailRowLayout
        key={dateLabel}
        icon={BiCalendar}
        label={dateLabel}
      />,
    ],
    earliestScheduledDate: startDates[0].toDate(),
    tagElement: (
      <FacilityOrProgrammeBookingBadge
        paymentStatus={pb.payment.status}
        status={pb.status}
      />
    ),
    linkTo:
      programmePaymentStatus === ProgrammePaymentState.HOLD ||
      programmePaymentStatus === ProgrammePaymentState.FAILED
        ? PROGRAMMES_ROUTES.review().paymentId(pb.payment.id)
        : PROGRAMMES_ROUTES.confirmation().paymentId(pb.payment.id),
  }
}

const mapFacilityBookingToCardProps = (
  fb: RouterOutput['booking']['list']['upcoming'][number],
): MappedBookingItemShape => {
  const condensedTimeslots = mergeTimeslots(fb.timeslots)

  const calendarLabels = condensedTimeslots.map((timeslot) => (
    <BookingCardDetailRowLayout
      key={dayjs(timeslot.startDateTime).tz().format()}
      icon={BiCalendar}
      label={`${dayjs(timeslot.startDateTime).tz().format('ddd, D MMM, h:mm a')} -
      ${dayjs(timeslot.endDateTime).tz().format('h:mm a')}`}
    />
  ))

  const getBookingLink = () => {
    if (fb.type === 'booking') {
      if (fb.paymentStatus === 'NONE' || fb.paymentStatus === 'FAILED') {
        return `/bookings/review/${fb.paymentId}`
      }
      return `${BOOKING_CONFIRMATION}/${fb.paymentId}`
    }

    return `${BALLOT_CONFIRMATION}/${fb.referenceId}`
  }

  const earliestScheduledDate = condensedTimeslots[0]?.startDateTime

  // Should never happen
  if (earliestScheduledDate === undefined) {
    throw new Error('Unable to find earliest scheduled date')
  }

  return {
    bookingId: fb.id,
    title: fb.activityName,
    details: [
      <BookingCardDetailRowLayout
        key={fb.venueName}
        icon={BiMap}
        label={fb.venueName}
      />,
      calendarLabels,
    ],
    earliestScheduledDate,
    linkTo: getBookingLink(),
    tagElement:
      fb.type === 'ballot' ? (
        <BallotBadge status={fb.status} />
      ) : (
        <FacilityOrProgrammeBookingBadge
          paymentStatus={fb.paymentStatus}
          status={fb.status}
        />
      ),
  }
}

export const useBookingListWithoutProgrammes = () => {
  const [
    {
      actionsRequired: actionableFacilityBookings,
      upcoming: upcomingFacilityBookings,
      past: pastFacilityBookings,
    },
  ] = trpc.booking.list.useSuspenseQuery()

  const upcomingItems: MappedBookingItemShape[] = useMemo(() => {
    return upcomingFacilityBookings
      .map(mapFacilityBookingToCardProps)
      .sort(
        (a, b) =>
          a.earliestScheduledDate.valueOf() - b.earliestScheduledDate.valueOf(),
      )
  }, [upcomingFacilityBookings])

  const actionableItems: MappedBookingItemShape[] = useMemo(() => {
    return actionableFacilityBookings
      .map(mapFacilityBookingToCardProps)
      .sort(
        (a, b) =>
          a.earliestScheduledDate.valueOf() - b.earliestScheduledDate.valueOf(),
      )
  }, [actionableFacilityBookings])

  const pastItems: MappedBookingItemShape[] = useMemo(() => {
    return pastFacilityBookings.map(mapFacilityBookingToCardProps)
  }, [pastFacilityBookings])

  return {
    upcomingItems,
    actionableItems,
    pastItems,
  }
}

export const useBookingListWithProgrammes = () => {
  const [
    {
      upcoming: upcomingProgrammeBookings,
      actionsRequired: actionableProgrammeBookings,
      past: pastProgrammeBookings,
    },
  ] = trpc.programme.programmeBooking.list.useSuspenseQuery()

  const [
    {
      actionsRequired: actionableFacilityBookings,
      upcoming: upcomingFacilityBookings,
      past: pastFacilityBookings,
    },
  ] = trpc.booking.list.useSuspenseQuery()

  const upcomingItems: MappedBookingItemShape[] = useMemo(() => {
    const upcomingProgrammeBookingProps: MappedBookingItemShape[] =
      upcomingProgrammeBookings.map(mapProgrammeBookingToCardProps)

    const upcomingFacilityBookingProps: MappedBookingItemShape[] =
      upcomingFacilityBookings.map(mapFacilityBookingToCardProps)

    const upcomingItemProps = [
      ...upcomingProgrammeBookingProps,
      ...upcomingFacilityBookingProps,
    ].sort(
      (a, b) =>
        a.earliestScheduledDate.valueOf() - b.earliestScheduledDate.valueOf(),
    )

    return upcomingItemProps
  }, [upcomingFacilityBookings, upcomingProgrammeBookings])

  const actionableItems: MappedBookingItemShape[] = useMemo(() => {
    const actionableProgrammeBookingProps: MappedBookingItemShape[] =
      actionableProgrammeBookings.map(mapProgrammeBookingToCardProps)

    const actionableFacilityBookingrops: MappedBookingItemShape[] =
      actionableFacilityBookings.map(mapFacilityBookingToCardProps)

    const actionableItemProps = [
      ...actionableProgrammeBookingProps,
      ...actionableFacilityBookingrops,
    ].sort(
      (a, b) =>
        a.earliestScheduledDate.valueOf() - b.earliestScheduledDate.valueOf(),
    )
    return actionableItemProps
  }, [actionableFacilityBookings, actionableProgrammeBookings])

  // TODO: Retrieve past programme bookings too
  const pastItems: MappedBookingItemShape[] = useMemo(() => {
    const pastFacilityBookingsProps = pastFacilityBookings.map(
      mapFacilityBookingToCardProps,
    )
    const pastProgrammeBookingsProps = pastProgrammeBookings.map(
      mapProgrammeBookingToCardProps,
    )

    const pastItemProps = [
      ...pastFacilityBookingsProps,
      ...pastProgrammeBookingsProps,
    ].sort(
      (a, b) =>
        b.earliestScheduledDate.valueOf() - a.earliestScheduledDate.valueOf(),
    )

    return pastItemProps
  }, [pastFacilityBookings, pastProgrammeBookings])

  return {
    upcomingItems,
    actionableItems,
    pastItems,
  }
}
