import { Box, Icon as ChakraIcon, Flex, Stack, Text } from '@chakra-ui/react'
import { type IconType } from 'react-icons/lib'

export const LandingPageSection = ({
  Icon,
  title,
  body,
}: {
  Icon: IconType
  title: string | JSX.Element
  body: string | JSX.Element
}) => {
  return (
    <Stack spacing={{ base: '0.25rem', md: '0.5rem' }}>
      <Stack
        alignItems={{ base: 'start', md: 'center' }}
        flexDirection={{ base: 'column', md: 'row' }}
        spacing="0.5rem"
      >
        <Flex
          align="center"
          bg="interaction.main-subtle.default"
          borderRadius="full"
          h="2.5rem"
          justify="center"
          w="2.5rem"
        >
          <ChakraIcon as={Icon} boxSize="1.25rem" color="brand.primary.500" />
        </Flex>
        <Text textStyle="h4-regular">{title}</Text>
      </Stack>
      {typeof body === 'string' ? (
        <Text textStyle="body-2">{body}</Text>
      ) : (
        <Box textStyle="body-2">{body}</Box>
      )}
    </Stack>
  )
}
