import { ld } from '@activesg/common/utilities'
import { useFeatureValue } from '@activesg/ui/libraries'

export function useIsFreePassEntryDate() {
  const { dates } = useFeatureValue('free-pass-entry-dates', {
    dates: [
      new Date('2024-09-10T00:00:00+08:00'),
      new Date('2024-10-10T00:00:00+08:00'),
      new Date('2024-11-10T00:00:00+08:00'),
      new Date('2024-12-10T00:00:00+08:00'),
    ],
  })

  const today = new Date()

  return dates.some((date) => ld.isSame(date, today))
}
