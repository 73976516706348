import { type SVGProps } from 'react'

import { chakra } from '@chakra-ui/react'

export const ProgrammeSvgr = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    viewBox="0 0 280 96"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5753_17004)">
      <rect fill="#F991B1" height="96" rx="4" width="280" />
      <g opacity="0.2">
        <mask
          height="53"
          id="mask0_5753_17004"
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="287"
          x="-3"
          y="56"
        >
          <rect fill="#D9D9D9" height="53" width="287" x="-3" y="56" />
        </mask>
        <g mask="url(#mask0_5753_17004)">
          <path
            d="M248.905 53.875H248.551H245.985H245.631L243.758 64.2685H245.985H248.551H250.779L248.905 53.875Z"
            fill="#694226"
          />
          <g opacity="0.5" style={{ mixBlendMode: 'multiply' }}>
            <path
              d="M245.203 59.7033C245.586 59.612 245.955 59.475 246.309 59.338C247.164 58.988 248.005 58.5163 248.58 57.7554C248.61 57.7098 248.226 58.8207 247.931 59.2772C248.359 59.1707 248.698 58.6685 248.905 58.3337C248.919 58.3033 249.554 58.9576 249.863 59.2315L248.89 53.875H245.63L244.568 59.7793C244.775 59.7793 244.996 59.7489 245.203 59.7033Z"
              fill="#966649"
            />
          </g>
          <path
            d="M232.4 63.2578H232.134H230.128H229.848L228.373 71.3839H230.128H232.134H233.875L232.4 63.2578Z"
            fill="#B66D44"
          />
          <g opacity="0.5" style={{ mixBlendMode: 'multiply' }}>
            <path
              d="M229.509 67.8074C229.804 67.7313 230.099 67.64 230.379 67.5183C231.043 67.2444 231.707 66.8791 232.149 66.2857C232.179 66.2552 231.869 67.1226 231.648 67.4878C231.972 67.4117 232.238 67.0161 232.415 66.7422C232.415 66.727 232.931 67.2291 233.167 67.4422L232.4 63.2422H229.849L229.008 67.8531C229.17 67.8531 229.347 67.8378 229.509 67.7922V67.8074Z"
              fill="#966649"
            />
          </g>
          <path
            d="M291.56 62.8516H12.4863V122.945H291.56V62.8516Z"
            fill="#A0BB30"
          />
          <path
            d="M12.9863 122.445V63.3516H291.06V122.445H12.9863Z"
            fill="#DE4E67"
            stroke="#9B0F2A"
          />
          <g opacity="0.5" style={{ mixBlendMode: 'multiply' }}>
            <path
              d="M12.9863 63.3438H291.06V73.6478C238.815 75.0279 206.559 84.6596 186.555 95.2639C166.778 105.748 159.004 117.167 155.734 122.437H12.9863V63.3438Z"
              fill="#9B0F2A"
              stroke="#9B0F2A"
            />
          </g>
          <path
            d="M-2.5 53.625H291.06V65.2121C275.878 65.7649 243.066 67.8733 212.185 75.8569C196.579 79.8914 181.44 85.4328 169.312 93.0498C157.303 100.593 148.206 110.197 144.576 122.427H-2.5V53.625Z"
            fill="#DE4E67"
            stroke="#9B0F2A"
          />
          <path
            d="M101.149 122.983L99.5098 122.604C99.6603 121.88 103.707 104.457 131.302 86.8971C156.589 70.8016 207.413 53 306.001 53V53.3447C251.799 53.3447 177.661 59.4279 132.189 88.3791C105.196 105.578 101.182 122.828 101.149 123V122.983Z"
            fill="#BB1E3D"
            stroke="#BB1E3D"
          />
          <path
            d="M49.5257 122.983L47.8867 122.603C48.0372 121.878 52.0845 104.421 79.6791 86.8258C104.966 70.6986 155.79 53 254.378 53V53.2072C200.176 53.2072 126.038 59.3024 80.5655 88.3108C53.5729 105.543 49.5591 122.827 49.5257 123V122.983Z"
            fill="#BB1E3D"
            stroke="#BB1E3D"
          />
          <path
            d="M0.840271 123L-0.787109 122.619C-0.637656 121.893 3.38098 104.401 30.7807 86.7716C55.8889 70.6125 106.354 53 204.23 53V53.0865C150.543 53.0865 77.0448 59.1592 31.8601 88.1211C4.94193 105.37 0.873483 122.827 0.840271 123Z"
            fill="#BB1E3D"
            stroke="#BB1E3D"
          />
        </g>
        <line stroke="#9B0F2A" x1="-3" x2="283" y1="55.5" y2="55.5" />
        <rect fill="#F991B1" height="32" width="37" x="18" y="31" />
        <path
          d="M47.75 22.75V23.25H48.25H53C55.3435 23.25 57.25 25.1565 57.25 27.5V60.75C57.25 63.0935 55.3435 65 53 65H19.75C17.4065 65 15.5 63.0935 15.5 60.75V27.5C15.5 25.1565 17.4065 23.25 19.75 23.25H24.5H25V22.75V18.5H28.75V22.75V23.25H29.25H43.5H44V22.75V18.5H47.75V22.75ZM53.5 32.25L53.5 31.75H53H19.75H19.25V32.25V60.75V61.25H19.75H53.0024H53.5024L53.5024 60.75L53.5 32.25ZM24.9996 39.875H28.7496V43.625H24.9996V39.875ZM24.9996 49.375H28.7496V53.125H24.9996V49.375ZM34.4996 39.875H38.2496V43.625H34.4996V39.875ZM34.4996 49.375H38.2496V53.125H34.4996V49.375ZM43.9996 39.875H47.7496V43.625H43.9996V39.875ZM43.9996 49.375H47.7496V53.125H43.9996V49.375Z"
          fill="#DE4E67"
          stroke="#9B0F2A"
        />
      </g>
      <path
        d="M198.175 105.583C204.489 99.9393 204.339 95.3234 204.588 92.2094L202.832 82.4453C201.407 83.4861 193.553 98.8255 190.433 101.173C183.271 106.561 178.689 118.845 177.43 124.151C177.276 125.349 179.341 132.315 217.23 145.597C219.982 146.562 225.468 144.277 220.011 140.678C209.958 134.047 192.209 124.64 192.209 124.64C204.539 109.478 191.862 111.227 198.175 105.583Z"
        fill="#CE8361"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M180.237 116.123L184.64 95.8879L208.182 94.516C208.182 94.516 198.846 116.123 195.897 119.086C192.949 122.05 180.237 116.123 180.237 116.123Z"
        fill="#FA6C2C"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M170.28 66.3563L177.421 62.3189C178.506 62.463 180.338 66.1326 179.921 68.0927C179.921 68.0927 172.68 74.7189 169.984 73.3942C167.288 72.0695 154.146 63.0778 149.672 54.3988C149.225 53.6825 149.409 52.8623 149.716 52.0336C149.46 51.3499 149.927 50.7624 150.388 50.6493C150.482 49.7642 151.244 49.4107 151.774 49.1887C152.958 48.6928 154.506 50.1282 155.422 51.2645C158.349 48.7388 161.543 50.8376 160.234 51.2308C157.586 52.0261 156.964 53.6483 156.942 53.7068C156.957 53.7123 157.185 53.9328 160.172 57.6494C163.507 61.7989 170.28 66.3563 170.28 66.3563Z"
        fill="#CE8361"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M198.361 48.0223C189.351 49.3409 179.493 56.6113 172.709 60.8614C172.709 60.8614 177.911 68.1809 179.7 70.4893L187.057 61.9825C180.469 73.0646 176.676 86.2183 175.721 92.2765C181.755 90.9592 192.044 93.3983 195.719 95.7565C198.66 97.6431 205.781 94.8402 209.166 94.5121C211.623 85.6668 207.469 68.6466 208.327 61.8747C211.09 63.6994 214.558 68.5154 217.779 69.9529L226.861 64.223C215.237 52.2433 208.327 52.7425 203.269 48.0222L198.361 48.0223Z"
        fill="#FEDECF"
      />
      <path
        d="M191.766 55.355C189.02 58.7261 189.346 58.1306 187.057 61.9825M205.964 58.2166C207.032 59.8974 207.524 60.5877 208.327 61.8747M208.327 61.8747C211.09 63.6994 214.558 68.5154 217.779 69.9529L226.861 64.223C215.237 52.2433 208.327 52.7425 203.269 48.0222L198.361 48.0223C189.351 49.3409 179.493 56.6113 172.709 60.8614C172.709 60.8614 177.911 68.1809 179.7 70.4893L187.057 61.9825M208.327 61.8747C207.469 68.6466 211.623 85.6668 209.166 94.5121C205.781 94.8402 198.66 97.6431 195.719 95.7565C192.044 93.3983 181.755 90.9592 175.721 92.2765C176.676 86.2183 180.469 73.0646 187.057 61.9825"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M155.712 50.6869C155.294 51.4723 153.291 50.8069 151.974 50.1513C150.657 49.4957 150.634 48.8911 150.942 48.3128C151.25 47.7345 151.773 47.4014 153.09 48.057C154.407 48.7126 156.131 49.9015 155.712 50.6869Z"
        fill="#CE8361"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M154.622 52.5853C154.204 53.3707 152.201 52.7054 150.884 52.0498C149.567 51.3942 149.544 50.7896 149.852 50.2112C150.16 49.6329 150.683 49.2998 152 49.9554C153.318 50.611 155.041 51.8 154.622 52.5853Z"
        fill="#CE8361"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M153.943 54.5385C153.524 55.3238 151.522 54.6585 150.204 54.0029C148.887 53.3473 148.864 52.7427 149.172 52.1644C149.481 51.586 150.003 51.253 151.321 51.9086C152.638 52.5642 154.361 53.7531 153.943 54.5385Z"
        fill="#CE8361"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M184.943 38.5441L185.281 25.0078L199.872 25.3719C199.776 29.2394 201.464 29.8212 201.795 30.6503C203.168 29.6878 205.89 29.5327 206.386 32.2189C206.882 34.905 205.04 36.4121 203.512 36.5498C202.236 42.5691 202.16 46.479 202.827 47.9615C201.35 50.7391 196.44 50.1475 194.169 49.5045L194.278 45.1096C184.849 45.1557 184.864 41.7084 184.943 38.5441Z"
        fill="#CE8361"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M194.455 45.1134C195.688 45.0882 197.72 44.7724 198.291 43.7722C198.316 43.7229 198.339 43.6747 198.362 43.6278C198.342 43.6774 198.318 43.7256 198.291 43.7722C197.391 45.6125 195.452 49.0859 194.349 49.3325L194.455 45.1134Z"
        fill="#490210"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M177.062 24.98C178.008 32.1805 183.192 28.9077 185.52 27.2653L185.52 25.7322C189.019 26.5231 189.236 27.7338 190.491 26.7683C190.32 29.2679 197.885 29.3695 200.359 28.9035C200.359 28.9035 201.148 30.4443 201.794 30.6462C202.936 29.502 205.585 29.5617 206.27 31.6896C207.184 34.5269 204.811 36.1095 203.51 36.5461L202.671 41.9782C203.531 42.7619 206.348 44.0753 209.33 43.0239C212.311 41.9725 211.137 38.73 210.177 37.2402C212.297 36.8826 216.353 35.0723 215.618 30.6916C214.883 26.3108 211.069 25.0077 209.254 24.9038C209.871 23.6878 210.423 20.3594 207.698 16.7733C204.973 13.1872 199.66 14.6378 197.343 15.8113C196.902 14.6862 194.788 12.4757 189.859 12.6341C184.93 12.7926 183.838 16.5884 183.908 18.4665C181.232 17.6374 176.115 17.7794 177.062 24.98Z"
        fill="#F1C8B6"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M185.088 28.1906L185.087 25.2422C185.087 25.2422 188.035 26.225 191.475 26.7164C194.423 27.2078 200.322 27.2078 200.322 27.2078C200.322 28.3872 200.977 29.501 201.305 30.1562C201.305 30.1562 194.425 29.6648 190.494 29.1734C186.563 28.682 185.088 28.1906 185.088 28.1906Z"
        fill="#CB0935"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M203.263 22.1212C203.582 21.2613 202.728 19.8804 200.773 19.4205C198.99 19.001 197.654 20.0172 197.405 20.4677"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M189.893 20.29C189.472 19.495 187.594 19.0556 185.978 20.1923C184.504 21.2291 184.682 23.0397 184.848 23.5167"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M186.818 31.3104C185.948 30.9369 184.351 31.4599 184.313 33.0069"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.619171"
      />
      <path
        d="M191.732 31.7849C192.264 31.6222 193.379 32.0371 193.64 32.8879"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.619171"
      />
      <path
        d="M194.051 37.5465C194.016 38.9528 193.106 40.189 191.801 40.8919C189.804 41.9678 187.442 41.6032 186.18 40.5757"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.619171"
      />
      <circle
        cx="191.684"
        cy="33.6807"
        fill="black"
        r="0.52755"
        transform="rotate(1.42954 191.684 33.6807)"
      />
      <circle
        cx="186.068"
        cy="33.1851"
        fill="black"
        r="0.52755"
        transform="rotate(1.42954 186.068 33.1851)"
      />
      <path
        d="M190.161 35.4001C189.901 34.4643 188.932 33.9163 187.996 34.1761C187.06 34.4359 186.512 35.4051 186.772 36.3409C186.926 36.8952 187.329 37.3135 187.826 37.5084"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.619171"
      />
      <circle
        cx="2.28605"
        cy="2.28605"
        fill="white"
        r="2.28605"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.52755"
        transform="matrix(-0.999689 -0.0249476 -0.0249476 0.999689 195.234 31.1382)"
      />
      <path
        d="M184.168 33.1488C184.137 34.411 185.134 35.4597 186.396 35.4912C186.777 35.5007 187.138 35.4167 187.458 35.2601C187.458 35.2601 187.467 34.8907 187.829 34.4715C188.169 34.0788 188.611 33.9633 188.611 33.9633C188.688 33.7436 188.733 33.5082 188.739 33.2629C188.77 32.0007 187.773 30.952 186.511 30.9205C185.248 30.889 184.2 31.8867 184.168 33.1488Z"
        fill="white"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.52755"
      />
      <path
        d="M199.792 31.7798C198.723 32.3394 196.373 33.4535 195.529 33.4325"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.52755"
      />
      <circle
        cx="192.523"
        cy="34.0611"
        fill="#490210"
        r="0.52755"
        transform="rotate(1.42954 192.523 34.0611)"
      />
      <circle
        cx="186.897"
        cy="33.9204"
        fill="#490210"
        r="0.52755"
        transform="rotate(1.42954 186.897 33.9204)"
      />
      <path
        d="M190.954 33.4941L194.118 33.5731"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.52755"
      />
      <path
        d="M184.625 33.3359L187.789 33.4149"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.52755"
      />
      <path
        d="M145.886 108.534C144.138 92.8761 142.703 76.1648 147.943 74.7471C153.182 73.3295 183.698 87.3357 183.267 88.6031C182.7 90.2707 173.788 99.9045 173.164 101.242C172.518 102.628 154.75 88.0214 154.277 88.1044C151.857 88.5289 150.656 100.66 152.163 109.753C152.163 109.753 147.634 124.193 145.886 108.534Z"
        fill="#CE8361"
      />
      <path
        d="M154.277 88.1044C151.857 88.5289 150.656 100.66 152.163 109.753C152.163 109.753 147.634 124.193 145.886 108.534C144.138 92.8761 142.703 76.1648 147.943 74.7471C153.182 73.3295 183.698 87.3357 183.267 88.6031C182.7 90.2707 173.788 99.9045 173.164 101.242C172.518 102.628 154.75 88.0214 154.277 88.1044ZM154.277 88.1044C153.328 87.4392 149.538 83.8466 149.471 83.4594"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M144.3 95.9922L152.654 95.9922C152.163 99.923 151.835 106.967 152.163 109.751L145.775 110.242L144.3 95.9922Z"
        fill="#CB0935"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M168.242 92.9859L175.308 83.9737C175.308 83.9737 188.367 85.342 192.29 88.6682C196.213 91.9945 208.498 93.8957 208.498 93.8957C198.933 105.903 181.274 104.524 170.368 101.086C166.898 99.9928 165.997 95.849 168.242 92.9859Z"
        fill="#FA6C2C"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <circle
        cx="152.178"
        cy="62.0504"
        fill="white"
        r="10.9282"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.690379"
        transform="rotate(-14.0136 152.178 62.0504)"
      />
      <path
        d="M157.563 70.4239C157.563 69.2447 157.236 67.6394 157.072 66.9841C156.679 67.3776 153.632 66.8201 152.158 66.4922L149.701 69.932L152.649 72.3891C153.829 72.7822 156.417 71.2428 157.563 70.4239Z"
        fill="#490210"
      />
      <path
        d="M142.821 64.5305L141.347 64.0391L142.33 66.987L144.296 69.9355L145.278 68.9526L142.821 64.5305Z"
        fill="#490210"
      />
      <path
        d="M159.53 55.1953L161.496 56.6695C162.282 57.4562 162.806 60.6006 162.97 62.0745L160.021 62.5659C160.021 61.3865 158.711 58.7985 158.056 57.6519L159.53 55.1953Z"
        fill="#490210"
      />
      <path
        d="M156.09 51.7575C157.237 52.5766 159.53 54.5096 159.53 55.6888M152.159 51.2656C151.34 52.0846 149.702 53.8209 149.702 54.2141M158.547 57.6539C157.564 57.3263 154.911 56.6711 152.159 56.6711M160.513 62.0765C159.857 63.5508 157.761 67.0889 156.581 67.482M149.702 61.0937C150.357 62.7318 151.864 66.2044 152.65 66.9906M145.771 60.1109C144.788 61.4213 142.822 64.2387 142.822 65.025M150.193 69.939C149.047 70.1028 146.36 70.1356 144.788 68.9562M153.142 71.9047C152.978 72.0685 152.552 72.4943 152.159 72.8875M157.073 70.4304L160.513 68.9562M145.771 56.6711L143.313 55.6888"
        stroke="#490210"
        strokeLinecap="round"
        strokeWidth="0.687968"
      />
      <path
        d="M152.649 56.6672C152.256 55.488 150.52 54.2102 149.701 53.7188L145.278 56.1753C144.885 56.5684 145.114 59.2875 145.278 60.5979C145.671 60.9911 148.391 61.417 149.701 61.5808L152.649 56.6672Z"
        fill="#490210"
      />
      <path
        d="M208.336 78.4196C208.783 76.8969 212.946 77.0512 215.779 77.6301C215.779 77.6301 219.625 77.041 221.187 76.4264C221.192 76.4267 221.197 76.4269 221.203 76.4271C222.153 76.4406 219.302 70.9467 218.698 69.4163C219.127 69.0194 224.032 65.941 224.992 65.2666C225.691 65.4282 229.854 71.1698 230.461 75.6327C231.068 80.0956 218.698 83.0386 218.698 83.0386C215.507 85.0126 210.475 87.0771 208.767 85.8476C207.699 85.0785 208.322 84.7815 209.111 84.6768C208.076 84.5678 207.589 83.996 207.542 83.7632C207.494 83.5303 207.192 82.8716 207.958 82.4374C206.845 82.1644 206.843 81.6032 206.985 81.1198C207.344 79.898 207.954 80.5663 210.978 78.8192C210.047 79.3297 207.719 80.5212 208.336 78.4196Z"
        fill="#E29B7B"
      />
      <path
        d="M207.958 82.4374C206.845 82.1644 206.843 81.6032 206.985 81.1198C207.344 79.898 207.954 80.5663 210.978 78.8192C210.047 79.3297 207.719 80.5212 208.336 78.4196C208.783 76.8969 212.946 77.0512 215.779 77.6301C215.779 77.6301 219.625 77.041 221.187 76.4264M207.958 82.4374C207.192 82.8716 207.494 83.5303 207.542 83.7632C207.589 83.996 208.076 84.5678 209.111 84.6768M207.958 82.4374C207.958 82.4374 207.852 82.9795 211.568 81.7424M209.111 84.6768C208.322 84.7815 207.699 85.0785 208.767 85.8476C210.475 87.0771 215.507 85.0126 218.698 83.0386C218.698 83.0386 231.068 80.0956 230.461 75.6327C229.854 71.1698 225.691 65.4282 224.992 65.2666C224.032 65.941 219.127 69.0194 218.698 69.4163C219.306 70.9549 222.183 76.4996 221.187 76.4264M209.111 84.6768C209.111 84.6768 210.326 85.0566 212.006 83.9069M221.187 76.4264C221.542 76.4437 222.765 76.3953 224.748 75.9422"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
    </g>
    <defs>
      <clipPath id="clip0_5753_17004">
        <rect fill="white" height="96" rx="4" width="280" />
      </clipPath>
    </defs>
  </svg>
))
