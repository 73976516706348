import { Stack } from '@chakra-ui/react'

import { useLoginState } from '@activesg/ui/hooks'

import { ErrorBoundary } from '~/components/ErrorBoundary'

import { PastItemsList } from './PastItemsList'
import { UpcomingItemsList } from './UpcomingItemsList'

export const UnactionableBookingHistorySection = (): JSX.Element | null => {
  const { hasLoginStateFlag } = useLoginState()

  if (hasLoginStateFlag) {
    return (
      <Stack spacing="1.5rem">
        <ErrorBoundary>
          <UpcomingItemsList />
        </ErrorBoundary>
        <ErrorBoundary>
          <PastItemsList />
        </ErrorBoundary>
      </Stack>
    )
  }

  return null
}
