import { Flex } from '@chakra-ui/react'

import { useLoginState } from '@activesg/ui/hooks'
import { type GetLayout } from '@activesg/ui/types'

import { HomeHeader } from '~/components/Header'
import { SignInHeader } from '~/features/sign-in/components'

export const HomeLayout: GetLayout = (page) => {
  const { hasLoginStateFlag } = useLoginState()

  return (
    <Flex flex={1} flexDir="column">
      {hasLoginStateFlag ? <HomeHeader /> : <SignInHeader showLoginButton />}
      <Flex flex={1} flexDir="column">
        {page}
      </Flex>
    </Flex>
  )
}
