import { type SVGProps } from 'react'

import { chakra } from '@chakra-ui/react'

export const MobileFacilityBookingSvgr = chakra(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      fill="none"
      height="96"
      viewBox="0 0 96 96"
      width="96"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4676_15484)">
        <rect fill="#FFDA58" height="96" rx="4" width="96" />
        <g opacity="0.2" style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M-131.436 125.648C-131.454 125.775 -131.417 125.904 -131.333 126.001C-131.25 126.098 -131.128 126.155 -131 126.156L61.9999 127.912C62.1272 127.914 62.2489 127.86 62.3341 127.766C62.4193 127.671 62.4599 127.544 62.4455 127.418C60.2166 107.817 50.9686 80.3244 30.4315 61.5743C9.86143 42.7942 -21.9624 32.8496 -69.1759 48.2489C-69.1935 48.2547 -69.2108 48.2615 -69.2276 48.2695C-78.4595 52.6213 -92.5247 61.6666 -105.023 74.763C-117.522 87.8602 -128.479 105.036 -131.436 125.648Z"
            fill="#F0C491"
            stroke="#9B7C0F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.888816"
          />
          <path
            d="M-19.2323 81.761C9.46426 43.6016 31.6931 59.1241 41.1351 69.6265C7.06958 27.2169 -40.8197 53.3136 -50.8789 75.2318L-20.8864 81.761H-19.2323Z"
            fill="#772E05"
          />
          <path
            d="M40.2703 68.2807C40.4441 68.4498 40.7215 68.4483 40.8935 68.2773C41.0655 68.1063 41.0686 67.829 40.9005 67.6541C40.4087 67.1425 39.8733 66.6276 39.2965 66.1159C14.9348 43.1067 -5.29594 39.8324 -20.7379 45.1691C-36.1329 50.4896 -46.6154 64.321 -51.6187 75.223C-51.679 75.3544 -51.6717 75.5068 -51.599 75.6318C-51.5264 75.7567 -51.3975 75.8385 -51.2536 75.8511L-51.2148 75.4084C-51.2536 75.8511 -51.2535 75.8511 -51.2534 75.8511L-51.2531 75.8511L-51.2515 75.8513L-51.2451 75.8518L-51.2194 75.8541L-51.1185 75.863C-51.0297 75.871 -50.8984 75.8827 -50.7288 75.8982C-50.3897 75.929 -49.8977 75.9743 -49.2862 76.0325C-48.0633 76.1487 -46.3631 76.316 -44.4543 76.5201C-40.6332 76.9288 -35.9883 77.4838 -32.6603 78.0713C-29.3259 78.6599 -26.3171 79.3124 -24.1418 79.8178C-23.0543 80.0705 -22.1755 80.2863 -21.5691 80.4388C-21.2658 80.5151 -21.0307 80.5755 -20.8716 80.6168C-20.8266 80.6285 -20.7877 80.6386 -20.755 80.6472C-20.7299 80.6538 -20.7085 80.6594 -20.6909 80.664L-20.6453 80.6761L-20.6339 80.6791L-20.6311 80.6798L-20.6305 80.68L-20.6303 80.6801C-20.4609 80.7253 -20.2806 80.6666 -20.1702 80.5303C-7.59525 64.9922 4.59825 59.3376 14.8754 58.6549C25.1608 57.9717 33.6081 62.2625 38.6963 66.7716C39.2189 67.2654 39.7436 67.7684 40.2703 68.2807Z"
            fill="#F0C491"
            stroke="#9B7C0F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.888816"
          />
        </g>
        <path
          d="M57.7669 66.034C48.17 66.7524 48.17 72.3503 48.17 72.3503C46.3745 77.019 46.9702 78.3501 46.9702 78.3501C46.9702 78.3501 49.3688 76.751 49.7701 80.7468C44.1726 95.1419 44.1217 106.111 42.6003 114.375C48.7669 113.194 55.6998 112.894 59.3736 115.387C62.3126 117.382 69.9451 117.39 73.394 117.146C70.0714 114.629 74.96 101.576 74.96 95.9407C74.96 90.3055 76.3412 85.8698 75.7597 80.7468C74.9525 73.637 77.2356 69.7361 76.5594 67.5521C76.3534 66.8868 72.5223 66.034 72.5223 66.034L63.5895 65.2215L57.7669 66.034Z"
          fill="#FA6C2C"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.768443"
        />
        <path
          d="M71.1621 97.9159C71.9243 96.1412 73.2795 100.385 76.1496 101.04C76.2779 101.325 88.8549 93.6136 90.1346 93.5442C88.9361 92.8626 77.6863 84.9645 75.2756 79.7557C73.2793 75.4423 76.0437 66.6878 76.9183 67.4607C94.0804 67.4607 97.9223 90.514 98.947 95.1246C99.9717 99.7352 77.2347 106.405 77.3252 107.246C74.4235 109.06 68.7901 112.491 68.7898 110.486C68.7897 109.752 69.4024 109.4 69.9308 109.183C69.0158 109.45 67.5688 109.566 67.5117 108.536C67.4733 107.845 68.4279 107.402 69.2121 107.132C67.5679 107.476 66.5142 106.715 67.6427 105.748C68.9768 104.604 71.2451 104.685 73.2793 102.611C72.4061 102.485 70.3999 99.6905 71.1621 97.9159Z"
          fill="#F5B896"
        />
        <path
          d="M70.1594 106.828C68.0239 107.747 66.3087 106.891 67.6427 105.748C68.9768 104.604 71.2451 104.685 73.2793 102.611C72.4061 102.485 70.3999 99.6906 71.1621 97.9159C71.9243 96.1412 73.2795 100.385 76.1496 101.04C76.284 101.338 90.0779 92.863 90.238 93.5877C90.2842 93.797 77.8275 85.2696 75.2756 79.7557C73.2793 75.4423 76.0437 66.6878 76.9183 67.4607C94.0804 67.4607 97.9223 90.514 98.947 95.1246C99.9717 99.7352 77.2347 106.405 77.3252 107.246C74.4235 109.06 68.7901 112.491 68.7898 110.486C68.7896 109.441 70.0335 109.17 70.505 108.961M70.1594 106.828C69.7838 106.99 67.4522 107.463 67.5117 108.536C67.5837 109.836 69.8671 109.31 70.505 108.961M70.1594 106.828C70.1594 106.828 70.8861 106.828 73.2793 105.15M70.505 108.961C70.505 108.961 71.9659 108.577 73.7448 107.246"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.768443"
        />
        <path
          d="M54.5034 53.658L56.7257 39.1292C56.8712 38.1779 59.3902 31.8083 68.6647 33.0338C77.9391 34.2592 77.7281 41.9509 77.7281 41.9509C77.1753 45.5649 75.7208 47.4194 74.1358 48.3027C75.84 47.6027 78.3165 47.2216 78.4599 49.7914C78.625 52.7517 75.338 53.3689 73.6739 53.3075C71.4735 59.6136 71.9519 64.5657 72.4661 66.2535C70.4949 69.0416 64.9205 66.7587 62.5638 65.7546L63.6534 62.0091C53.4938 60.7641 53.984 57.0543 54.5034 53.658Z"
          fill="#F5B896"
          stroke="#490210"
          strokeLinejoin="round"
          strokeWidth="0.768443"
        />
        <path
          d="M63.8738 62.0341C65.2054 62.1763 67.4381 62.1153 68.1898 61.116C68.2225 61.0663 68.2541 61.0179 68.2846 60.9709C68.2564 61.0213 68.2247 61.0697 68.1898 61.116C66.9632 62.984 64.3014 66.6607 63.0801 66.7749L63.8738 62.0341Z"
          fill="#490210"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.768443"
        />
        <path
          d="M53.9885 29.4325C51.0957 27.1362 50.2496 29.8904 50.1882 31.5546C50.704 35.7482 53.415 38.681 56.9085 40.6882C62.3866 40.1744 68.7153 41.7217 72.5623 43.0825C72.651 45.0271 72.8412 47.5709 73.4517 48.6298C76.5151 46.7811 78.1629 48.1274 78.6039 49.0316C88.8614 42.5676 82.0078 36.0353 77.2988 33.5772C80.071 30.6026 77.1072 30.2008 75.2788 30.3717C74.9042 26.7614 72.897 28.2695 71.9402 29.4749C68.282 28.1429 65.6205 21.5567 62.7614 21.3124C60.4741 21.1171 59.9436 24.1641 59.9643 25.7121C59.6543 25.2141 58.4509 24.2448 56.1174 24.3513C53.7839 24.4578 53.7259 27.7831 53.9885 29.4325Z"
          fill="#490210"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.768443"
        />
        <path
          d="M55.9024 43.4276L56.313 40.7428C61.1039 40.1567 68.8696 42.1139 72.1536 43.1657L72.1293 45.9096C64.9906 43.466 58.336 43.0274 55.9024 43.4276Z"
          fill="#CB0935"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.768443"
        />
        <path
          d="M62.7169 27.8806C63.6329 29.8874 66.9748 34.6338 73.0145 37.5659M60.8499 33.7742C61.0245 34.3159 61.8528 35.5497 63.7689 36.1517"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.768443"
        />
        <path
          d="M63.2395 50.7417C63.0882 49.6984 62.1198 48.9753 61.0765 49.1265C60.0333 49.2778 59.3101 50.2461 59.4614 51.2894C59.551 51.9075 59.9273 52.4131 60.4361 52.6913"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.645492"
        />
        <path
          d="M64.068 46.6566C64.8328 45.9698 66.656 45.9524 67.2338 47.5296"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.645492"
        />
        <path
          d="M60.1502 45.7254C59.8135 45.2244 58.5898 44.8104 57.7883 45.3491"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.645492"
        />
        <path
          d="M67.6652 51.0247C67.623 52.563 66.4564 55.3924 64.1584 56.2768C61.8604 57.1611 59.7026 56.3721 58.6571 55.6329"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.645492"
        />
        <path
          d="M65.9755 49.395C65.815 49.7905 65.5071 50.2115 64.8784 50.0552C64.2926 49.9095 64.0577 49.3535 64.2033 48.7677"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.645492"
        />
        <path
          d="M59.5589 48.1434C59.4166 48.5458 59.1281 48.9804 58.493 48.8528C57.9012 48.734 57.6412 48.1892 57.76 47.5974"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.645492"
        />
        <path
          d="M62.2809 75.2965C59.4365 75.0182 61.0309 69.3294 62.5678 65.2311L72.1992 66C71.1736 71.5256 65.8364 75.6444 62.2809 75.2965Z"
          fill="#F5B896"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.725341"
        />
        <path
          d="M71.7346 66.5123L71.8789 65.5688L66.1532 64.7229L62.7954 66.8829L71.7346 66.5123Z"
          fill="#F5B896"
        />
        <path
          d="M61.6565 67.6344C61.6565 67.6344 65.2805 65.7294 65.642 64.7202C66.0035 63.711 61.9428 65.7622 61.9428 65.7622L61.6565 67.6344Z"
          fill="#490210"
        />
        <path
          d="M17.1743 65.2517C19.335 67.1969 17.1743 71.4663 17.1743 71.4663C17.1743 71.4663 15.824 71.811 20.5091 75.5348C25.1942 79.2586 33.9907 83.257 33.9907 83.257C33.9907 83.257 38.3583 75.5606 43.3673 71.4663C48.8612 66.9756 55.9815 66.055 56.7817 66.0535C57.5819 66.052 57.5159 67.3356 56.7816 68.844C55.4764 71.5248 51.354 79.2223 49.9843 80.8458C49.1847 78.047 45.3241 84.8314 39.1961 88.6747C36.7965 90.1797 33.4225 93.1569 30.6392 91.7893C27.8559 90.4216 14.2875 81.1389 9.66885 72.179C9.2073 71.4395 9.39752 70.5928 9.71418 69.7372C9.45009 69.0314 9.93298 68.4249 10.4087 68.3081C10.5059 67.3944 11.2921 67.0295 11.8392 66.8002C13.0612 66.2883 13.4526 67.6772 14.3985 68.8502C15.4754 67.9209 15.1378 66.8502 15.1748 65.651C15.1852 65.3148 15.3716 63.6289 17.1743 65.2517Z"
          fill="#F5B896"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.760969"
        />
        <path
          d="M37.6914 42.0382L17.2492 64.2317"
          stroke="#490210"
          strokeMiterlimit="10"
          strokeWidth="2.36705"
        />
        <path
          d="M5.58978 72.1711L15.4769 61.4781C16.0631 60.8462 17.0417 60.8102 17.6677 61.3938L20.2868 63.8172C20.9187 64.4035 20.9547 65.382 20.3712 66.008L10.4841 76.701C9.8978 77.3329 8.91924 77.3689 8.29324 76.7853L5.67408 74.3619C5.0395 73.7815 5.00351 72.8029 5.58978 72.1711Z"
          fill="#FE641F"
          stroke="#490210"
          strokeWidth="0.767693"
        />
        <path
          d="M37.9203 38.6327L34.9922 41.9075C34.9922 41.9075 35.7236 44.2998 38.3905 44.7079L41.4375 41.7593L37.9289 38.6296L37.9203 38.6327Z"
          fill="#FE641F"
          stroke="#490210"
          strokeWidth="0.767693"
        />
        <path
          d="M46.8424 20.5288C51.2454 20.2989 55.6598 20.06 60.0629 19.8301"
          stroke="#490210"
          strokeMiterlimit="10"
          strokeWidth="0.767693"
        />
        <path
          d="M43.1784 24.4178C49.2595 24.0573 55.3461 23.685 61.4214 23.3217"
          stroke="#490210"
          strokeMiterlimit="10"
          strokeWidth="0.767693"
        />
        <path
          d="M39.9175 28.5655C46.7851 28.1223 53.6618 27.6904 60.5353 27.2499"
          stroke="#490210"
          strokeMiterlimit="10"
          strokeWidth="0.767693"
        />
        <path
          d="M39.0919 32.6628C45.4696 32.3691 51.8302 32.0817 58.2079 31.788"
          stroke="#490210"
          strokeMiterlimit="10"
          strokeWidth="0.767693"
        />
        <path
          d="M37.7194 36.9909C43.4963 36.7025 49.27 36.4054 55.0527 36.1196"
          stroke="#490210"
          strokeMiterlimit="10"
          strokeWidth="0.767693"
        />
        <path
          d="M53.3796 17.646C49.5654 25.3029 46.1264 33.1488 43.0788 41.1484"
          stroke="#490210"
          strokeMiterlimit="10"
          strokeWidth="0.767693"
        />
        <path
          d="M50.0067 17.8294C45.4104 24.9014 41.7652 32.6086 39.2245 40.6518"
          stroke="#490210"
          strokeMiterlimit="10"
          strokeWidth="0.767693"
        />
        <path
          d="M57.6602 17.7739C54.4348 25.0995 51.1951 32.4254 47.9697 39.751"
          stroke="#490210"
          strokeMiterlimit="10"
          strokeWidth="0.767693"
        />
        <path
          d="M61.4414 18.8638C58.5865 25.4855 55.7258 32.1045 52.8677 38.7176"
          stroke="#490210"
          strokeMiterlimit="10"
          strokeWidth="0.767693"
        />
        <path
          d="M61.233 17.4923C57.5768 13.9449 49.4725 16.3557 43.1246 22.89C36.7794 29.4185 34.5978 37.5933 38.2566 41.1349C41.9073 44.694 50.0171 42.2714 56.3564 35.7403C62.7075 29.2145 64.8864 21.0455 61.233 17.4923ZM55.0884 34.5158C49.8753 39.8828 43.2626 41.9053 40.2985 39.0292C37.3461 36.1585 39.1709 29.4846 44.3871 24.1262C49.5944 18.7564 56.2129 16.7367 59.1798 19.607C62.1322 22.4777 60.3133 29.1542 55.0884 34.5158Z"
          fill="#FE641F"
          stroke="#490210"
          strokeWidth="0.767693"
        />
        <path
          d="M18.0048 66.4516C18.6446 68.052 16.9386 70.7169 16.4055 71.6495L14.4063 72.8485C14.5395 71.9155 15.6058 69.6462 15.6058 68.0486C15.6058 66.4 14.7099 65.7412 15.6058 64.849C16.4055 64.0526 17.2051 64.4512 18.0048 66.4516Z"
          fill="#F5B896"
        />
        <path
          d="M17.2051 71.6471C17.7383 70.7145 18.6446 68.0519 18.0048 66.4515C17.2051 64.4511 16.4055 64.0525 15.6058 64.8489C14.7099 65.7411 15.6058 66.3999 15.6058 68.0485C15.6058 69.6461 14.5395 71.9154 14.4063 72.8484"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.767693"
        />
        <path
          d="M15.1141 65.9817C14.6819 66.7925 12.6147 66.1056 11.2548 65.4288C9.89489 64.752 9.87121 64.1278 10.1894 63.5308C10.5077 62.9337 11.0473 62.5899 12.4072 63.2667C13.7671 63.9435 15.5462 65.1709 15.1141 65.9817Z"
          fill="#F5B896"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.760969"
        />
        <path
          d="M13.743 67.5755C13.3109 68.3863 11.2436 67.6994 9.8837 67.0226C8.5238 66.3458 8.50012 65.7216 8.81834 65.1245C9.13656 64.5275 9.67618 64.1836 11.0361 64.8604C12.396 65.5373 14.1751 66.7647 13.743 67.5755Z"
          fill="#F5B896"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.760969"
        />
        <path
          d="M12.618 69.5394C12.1859 70.3502 10.1186 69.6633 8.7587 68.9864C7.3988 68.3096 7.37512 67.6854 7.69334 67.0884C8.01156 66.4913 8.55118 66.1475 9.91108 66.8243C11.271 67.5011 13.0501 68.7286 12.618 69.5394Z"
          fill="#F5B896"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.760969"
        />
        <path
          d="M11.9188 71.5647C11.4866 72.3755 9.41939 71.6886 8.05948 71.0118C6.69958 70.335 6.6759 69.7108 6.99412 69.1138C7.31234 68.5167 7.85196 68.1729 9.21186 68.8497C10.5718 69.5265 12.3509 70.754 11.9188 71.5647Z"
          fill="#F5B896"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.760969"
        />
        <path
          d="M24.9852 24.77L21.7865 22.3647L17.7881 29.1683L22.1863 30.3678L24.9852 24.77Z"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.767693"
        />
        <line
          stroke="#490210"
          strokeWidth="0.79968"
          x1="20.3922"
          x2="24.3906"
          y1="23.6322"
          y2="26.0313"
        />
        <line
          stroke="#490210"
          strokeWidth="0.799681"
          x1="18.7638"
          x2="23.5619"
          y1="25.2112"
          y2="27.6103"
        />
        <line
          stroke="#490210"
          strokeWidth="0.799681"
          x1="18.7335"
          x2="22.7319"
          y1="27.5951"
          y2="29.1944"
        />
        <path
          d="M27.0744 29.2182C26.2582 30.8351 23.975 31.3278 21.9797 30.3157L24.94 24.4602C26.939 25.4676 27.8943 27.5966 27.0781 29.2134L27.0744 29.2182Z"
          fill="#F1EFE7"
          stroke="#490210"
          strokeWidth="0.767693"
        />
        <path
          d="M24.9359 24.4605L21.9795 30.3076L20.9873 29.8059L23.9437 23.9588L24.9359 24.4605Z"
          fill="#FE641F"
          stroke="#490210"
          strokeWidth="0.767693"
        />
        <path
          d="M21.7691 22.3219L21.316 22.7918C21.316 22.7918 21.2107 22.7122 21.0205 22.5758C21.3314 22.8411 21.5204 22.999 21.5204 22.999L20.7974 23.7762C20.7974 23.7762 20.7471 23.7532 20.6549 23.706L19.6815 25.6103L19.6575 25.5922L18.8495 27.5463C18.8543 27.5499 18.8591 27.5536 18.8591 27.5536L18.6571 28.5968C18.6571 28.5968 18.4185 28.5443 18.0192 28.4455C18.2434 28.5172 18.3692 28.5597 18.3692 28.5597L18.2611 29.2001C18.2611 29.2001 6.73417 28.1497 7.19549 27.7087C7.58817 27.3286 9.03225 26.5926 9.95478 26.3574C9.88171 26.3247 9.8398 26.3006 9.82906 26.2849C9.65618 25.9962 6.81011 24.04 7.30587 23.6927C7.57902 23.5005 8.51194 23.3709 9.36653 23.3251C8.69041 22.528 7.91109 21.4198 8.58043 21.1812C9.24615 20.9473 10.2758 20.9285 10.9598 20.9492C10.3281 20.1331 9.20993 18.528 10.0244 18.3163C10.4376 18.2075 10.9789 18.188 11.4912 18.2068C11.1347 17.6138 10.8478 17.0283 10.8368 16.7642C10.8212 16.1582 14.0832 17.2856 14.4181 17.2605C14.4397 17.2618 14.4853 17.2812 14.5547 17.3187C14.1969 16.4389 13.9355 14.8346 14.006 14.2937C14.0889 13.6568 21.7643 22.3183 21.7643 22.3183L21.7691 22.3219Z"
          fill="#F1EFE7"
          stroke="#490210"
          strokeLinejoin="round"
          strokeWidth="0.767693"
        />
        <path
          d="M21.316 22.7918L21.7691 22.3219L21.7643 22.3183C21.7643 22.3183 14.0889 13.6568 14.006 14.2937C13.9355 14.8346 14.1969 16.4389 14.5547 17.3187C14.4853 17.2812 14.4397 17.2618 14.4181 17.2605C14.0832 17.2856 10.8212 16.1582 10.8368 16.7642C10.8478 17.0283 11.1347 17.6138 11.4912 18.2068C10.9789 18.188 10.4376 18.2075 10.0244 18.3163C9.20993 18.528 10.3281 20.1331 10.9598 20.9492C10.2758 20.9285 9.24615 20.9473 8.58043 21.1812C7.91109 21.4198 8.69041 22.528 9.36653 23.3251C8.51194 23.3709 7.57902 23.5005 7.30587 23.6927C6.81011 24.04 9.65618 25.9962 9.82906 26.2849C9.8398 26.3005 9.88171 26.3247 9.95478 26.3574C9.03226 26.5926 7.58817 27.3286 7.19549 27.7087C6.73417 28.1497 18.2611 29.2001 18.2611 29.2001L18.3692 28.5597M21.5204 22.999L20.7974 23.7762C20.7974 23.7762 20.7471 23.7532 20.6549 23.706L19.6815 25.6103L19.6575 25.5922L18.8495 27.5463C18.8543 27.5499 18.8591 27.5536 18.8591 27.5536L18.6571 28.5968"
          stroke="#490210"
          strokeLinejoin="round"
          strokeWidth="0.767693"
        />
        <path
          d="M12.0567 21.4262C12.0567 21.4262 15.6305 23.361 16.6667 24.2138C16.6667 24.2138 13.5028 21.6625 11.4745 21.0391C9.44618 20.4157 11.5363 21.1226 11.5363 21.1226L12.0606 21.4292"
          fill="#F1EFE7"
        />
        <path
          d="M12.0567 21.4262C12.0567 21.4262 15.6305 23.361 16.6667 24.2138C16.6667 24.2138 13.5028 21.6625 11.4745 21.0391C9.44618 20.4157 11.5363 21.1226 11.5363 21.1226L12.0606 21.4292"
          stroke="#490210"
          strokeLinejoin="round"
          strokeWidth="0.767693"
        />
        <path
          d="M10.459 23.7656C10.459 23.7656 14.0329 25.7004 15.0691 26.5532C15.0691 26.5532 11.9051 24.0018 9.87682 23.3785C7.84852 22.7551 9.93868 23.4619 9.93868 23.4619L10.463 23.7686"
          fill="#F1EFE7"
        />
        <path
          d="M10.459 23.7656C10.459 23.7656 14.0329 25.7004 15.0691 26.5532C15.0691 26.5532 11.9051 24.0018 9.87682 23.3785C7.84852 22.7551 9.93868 23.4619 9.93868 23.4619L10.463 23.7686"
          stroke="#490210"
          strokeLinejoin="round"
          strokeWidth="0.767693"
        />
        <path
          d="M10.6254 26.6094C10.6254 26.6094 12.786 27.2125 13.5078 27.411C13.5078 27.411 11.321 26.809 10.2499 26.5053C9.17884 26.2017 10.3036 26.5198 10.3036 26.5198L10.6281 26.6102"
          fill="#F1EFE7"
        />
        <path
          d="M10.6254 26.6094C10.6254 26.6094 12.786 27.2125 13.5078 27.411C13.5078 27.411 11.321 26.809 10.2499 26.5053C9.17884 26.2017 10.3036 26.5198 10.3036 26.5198L10.6281 26.6102"
          stroke="#490210"
          strokeLinejoin="round"
          strokeWidth="0.767693"
        />
        <path
          d="M12.3802 18.6558L17.3438 22.2617C17.3438 22.2617 11.6647 19.2113 11.5888 18.121L12.3802 18.6558Z"
          fill="#F1EFE7"
          stroke="#490210"
          strokeLinejoin="round"
          strokeWidth="0.767693"
        />
        <path
          d="M14.1875 17.1831C15.1208 18.2493 16.1867 19.5821 18.1859 20.7754"
          stroke="#490210"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.767693"
        />
        <path
          d="M18.5658 29.3988C18.5658 29.3988 19.4911 25.3595 22.0195 22.5625L21.2403 21.7326C21.2403 21.7326 17.9731 25.3476 17.4806 29.1726L18.5622 29.4036L18.5658 29.3988Z"
          fill="#FE641F"
          stroke="#490210"
          strokeWidth="0.767693"
        />
      </g>
      <defs>
        <clipPath id="clip0_4676_15484">
          <rect fill="white" height="96" rx="4" width="96" />
        </clipPath>
      </defs>
    </svg>
  ),
)
