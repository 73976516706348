import NextLink from 'next/link'

import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import { Link } from '@opengovsg/design-system-react'

import { FreePassSvgr } from '~/components/Svgs/FreePassSvgr'

export const FreePassBanner = () => {
  return (
    <HStack
      bg="base.canvas.brand-subtle"
      borderRadius="1rem"
      justifyContent="space-between"
      p="1.5rem"
    >
      <Stack flex={1} spacing={0}>
        <Text textStyle="body-2">Every 10th of the month</Text>
        <Text color="base.content.strong" textStyle="h6">
          Enjoy free pool and gym entry today
        </Text>
      </Stack>
      <Box h="4.5rem" w="4.5rem">
        <FreePassSvgr />
      </Box>
    </HStack>
  )
}

export const FreePassInfographic = () => {
  return (
    <Stack
      alignItems="center"
      bg="base.canvas.brand-subtle"
      borderRadius="1rem"
      h="22.5rem"
      justifyContent="center"
      p="1.5rem"
      spacing="1.5rem"
    >
      <Box h="6rem" w="6rem">
        <FreePassSvgr />
      </Box>
      <Stack spacing="0.5rem" textAlign="center">
        <Text color="base.content.strong" textStyle="h5-regular">
          Enjoy free entry for our 10th anniversary
        </Text>
        <Text color="base.content.strong">
          From May to December 2024, enter pools and gyms for free every 10th of
          the month.
        </Text>
      </Stack>
    </Stack>
  )
}

export const GlobalClosureBanner = () => {
  return (
    <HStack
      bg="base.canvas.brand-subtle"
      borderRadius="1rem"
      justifyContent="space-between"
      p="1.5rem"
    >
      <Stack flex={1} spacing={0}>
        <Text color="base.content.strong" textStyle="h6">
          Pools and gyms are closed today, 21 Oct
        </Text>
      </Stack>
      <Box h="4.5rem" w="4.5rem">
        <FreePassSvgr />
      </Box>
    </HStack>
  )
}

export const GlobalClosureInfographic = () => {
  return (
    <Stack
      alignItems="center"
      bg="base.canvas.brand-subtle"
      borderRadius="1rem"
      h="22.5rem"
      justifyContent="center"
      p="1.5rem"
      spacing="1.5rem"
    >
      <Box h="6rem" w="6rem">
        <FreePassSvgr />
      </Box>
      <Stack spacing="0.5rem" textAlign="center">
        <Text color="base.content.strong" textStyle="h5-regular">
          Pools and gyms are closed today, 21 Oct
        </Text>
        <Text color="base.content.strong">
          Already purchased passes?{' '}
          <Link
            as={NextLink}
            href="https://go.gov.sg/activesg-support"
            target="_blank"
          >
            Contact us
          </Link>{' '}
          for a refund.
        </Text>
      </Stack>
    </Stack>
  )
}
