import { useMemo, useState } from 'react'

import NextLink from 'next/link'

import {
  Box,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { Link } from '@opengovsg/design-system-react'

import {
  FACILITY_BOOKING_ROUTES,
  PASSES_ROUTES,
  PROGRAMMES_ROUTES,
} from '@activesg/common/routes/member'

import { BookingCard } from '../BookingHistorySection/BookingCard'
import { ActionableWrapper } from './ActionableWrapper'
import { NoItems } from './NoItems'
import { useOverviewList } from './useOverviewList'

export const OverviewSectionView = ({
  overview,
  availableStates,
}: {
  overview: ReturnType<typeof useOverviewList>
  availableStates: ('bookings' | 'programmes' | 'passes')[]
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(() => {
    // initial selected index is the first entity that has actionable items
    // if none, then the first entity that has upcoming items
    for (const [index, state] of availableStates.entries()) {
      if (overview[state].actionable.length > 0) {
        return index
      }
    }
    for (const [index, state] of availableStates.entries()) {
      if (overview[state].upcoming.length > 0) {
        return index
      }
    }
    return 0
  })

  const itemLengths = useMemo(() => {
    const bookingLength =
      overview.bookings.actionable.length + overview.bookings.upcoming.length
    const bookings = {
      length: bookingLength,
      label: `See ${bookingLength} ${bookingLength === 1 ? 'booking' : 'bookings'}`,
      href: FACILITY_BOOKING_ROUTES.myBookings(),
    }

    const programmeLength =
      overview.programmes.actionable.length +
      overview.programmes.upcoming.length
    const programmes = {
      length: programmeLength,
      label: `See ${programmeLength} ${programmeLength === 1 ? 'programme' : 'programmes'}`,
      href: PROGRAMMES_ROUTES.myProgrammes(),
    }

    const passLength =
      overview.passes.actionable.length + overview.passes.upcoming.length
    const passes = {
      length: passLength,
      label: `See ${passLength} ${passLength === 1 ? 'pass' : 'passes'}`,
      href: PASSES_ROUTES.myPasses().root(),
    }

    return {
      bookings,
      programmes,
      passes,
    }
  }, [overview])

  const itemCombined = useMemo(() => {
    return {
      // explictly ordered as such since actionable takes priority
      bookings: [
        ...overview.bookings.actionable.map((item) => ({
          isActionable: true,
          item,
        })),
        ...overview.bookings.upcoming.map((item) => ({
          isActionable: false,
          item,
        })),
      ],
      programmes: [
        ...overview.programmes.actionable.map((item) => ({
          isActionable: true,
          item,
        })),
        ...overview.programmes.upcoming.map((item) => ({
          isActionable: false,
          item,
        })),
      ],
      passes: [
        ...overview.passes.actionable.map((item) => ({
          isActionable: true,
          item,
        })),
        ...overview.passes.upcoming.map((item) => ({
          isActionable: false,
          item,
        })),
      ],
    }
  }, [overview])

  // we don't render overview section if there are no actionable or upcoming items
  if (
    itemCombined.bookings.length === 0 &&
    itemCombined.programmes.length === 0 &&
    itemCombined.passes.length === 0
  ) {
    return null
  }

  return (
    <Tabs
      index={selectedIndex}
      variant="monochrome-pill"
      onChange={setSelectedIndex}
    >
      <TabList>
        {availableStates.map((state) => (
          <Tab key={state}>
            {overview[state].actionable.length > 0 && (
              <Box
                // red notification at top right hand corner
                bg="utility.feedback.critical"
                borderColor="base.content.inverse"
                borderRadius="50%"
                borderWidth="1px"
                h="calc(0.5rem + 2px)"
                position="absolute"
                right="0"
                top="0"
                w="calc(0.5rem + 2px)"
              />
            )}
            {state}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {availableStates.map((state) => (
          <TabPanel key={state}>
            <Stack pt="1rem" spacing="1rem">
              {itemCombined[state].slice(0, 1).map((item) => {
                return (
                  <ActionableWrapper
                    key={item.item.bookingId}
                    actionable={item.isActionable}
                  >
                    <BookingCard key={item.item.bookingId} {...item.item} />
                  </ActionableWrapper>
                )
              })}
              {itemLengths[state].length === 0 && <NoItems type={state} />}
              {itemLengths[state].length > 0 && (
                <Stack alignItems="center">
                  <Link
                    as={NextLink}
                    href={itemLengths[state].href}
                    textColor="base.content.strong"
                    textStyle="subhead-2"
                  >
                    {itemLengths[state].label}
                  </Link>
                </Stack>
              )}
            </Stack>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

export const OverviewSection = () => {
  const overview = useOverviewList()
  return (
    <OverviewSectionView
      availableStates={['passes', 'bookings', 'programmes']}
      overview={overview}
    />
  )
}
