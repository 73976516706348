import { type SVGProps } from 'react'

import { chakra } from '@chakra-ui/react'

export const MobileProgrammeSvgr = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="96"
    viewBox="0 0 96 96"
    width="96"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4676_15635)">
      <rect fill="#F991B1" height="96" rx="4" width="96" />
      <g opacity="0.2">
        <mask
          height="53"
          id="mask0_4676_15635"
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="287"
          x="-138"
          y="56"
        >
          <rect fill="#D9D9D9" height="53" width="287" x="-138" y="56" />
        </mask>
        <g mask="url(#mask0_4676_15635)">
          <path
            d="M97.3977 63.2578H97.1322H95.1263H94.846L93.3711 71.3839H95.1263H97.1322H98.8726L97.3977 63.2578Z"
            fill="#B66D44"
          />
          <g opacity="0.5" style={{ mixBlendMode: 'multiply' }}>
            <path
              d="M94.5093 67.8074C94.8043 67.7313 95.0993 67.64 95.3795 67.5183C96.0432 67.2444 96.7069 66.8791 97.1494 66.2857C97.1789 66.2552 96.8692 67.1226 96.6479 67.4878C96.9724 67.4117 97.2379 67.0161 97.4149 66.7422C97.4149 66.727 97.9311 67.2291 98.1671 67.4422L97.4002 63.2422H94.8485L94.0078 67.8531C94.1701 67.8531 94.347 67.8378 94.5093 67.7922V67.8074Z"
              fill="#966649"
            />
          </g>
          <path
            d="M156.558 62.8516H-122.516V122.945H156.558V62.8516Z"
            fill="#A0BB30"
          />
          <path
            d="M-122.016 122.445V63.3516H156.058V122.445H-122.016Z"
            fill="#DE4E67"
            stroke="#9B0F2A"
          />
          <g opacity="0.5" style={{ mixBlendMode: 'multiply' }}>
            <path
              d="M-122.016 63.3438H156.058V73.6478C103.813 75.0279 71.5575 84.6596 51.5531 95.2639C31.776 105.748 24.0016 117.167 20.7321 122.437H-122.016V63.3438Z"
              fill="#9B0F2A"
              stroke="#9B0F2A"
            />
          </g>
          <path
            d="M-137.5 53.625H156.06V65.2121C140.878 65.7649 108.066 67.8733 77.1848 75.8569C61.5792 79.8914 46.4401 85.4328 34.3124 93.0498C22.3031 100.593 13.2058 110.197 9.57628 122.427H-137.5V53.625Z"
            fill="#DE4E67"
            stroke="#9B0F2A"
          />
          <path
            d="M-33.8532 122.983L-35.4922 122.604C-35.3417 121.88 -31.2945 104.457 -3.6998 86.8971C21.5869 70.8016 72.4113 53 170.999 53V53.3447C116.797 53.3447 42.6592 59.4279 -2.81343 88.3791C-29.806 105.578 -33.8198 122.828 -33.8532 123V122.983Z"
            fill="#BB1E3D"
            stroke="#BB1E3D"
          />
          <path
            d="M-85.4782 122.983L-87.1172 122.603C-86.9667 121.878 -82.9195 104.421 -55.3248 86.8258C-30.0381 70.6986 20.7863 53 119.374 53V53.2072C65.1719 53.2072 -8.96578 59.3024 -54.4384 88.3108C-81.431 105.543 -85.4448 122.827 -85.4782 123V122.983Z"
            fill="#BB1E3D"
            stroke="#BB1E3D"
          />
          <path
            d="M-134.162 123L-135.789 122.619C-135.64 121.893 -131.621 104.401 -104.221 86.7716C-79.1131 70.6125 -28.6477 53 69.2276 53V53.0865C15.5407 53.0865 -57.9571 59.1592 -103.142 88.1211C-130.06 105.37 -134.128 122.827 -134.162 123Z"
            fill="#BB1E3D"
            stroke="#BB1E3D"
          />
        </g>
        <line stroke="#9B0F2A" x1="-138" x2="148" y1="55.5" y2="55.5" />
      </g>
      <path
        d="M63.1755 105.583C69.4891 99.9393 69.3391 95.3234 69.5877 92.2094L67.832 82.4453C66.4068 83.4861 58.5529 98.8255 55.4331 101.173C48.271 106.561 43.6889 118.845 42.4297 124.151C42.276 125.349 44.3413 132.315 82.2302 145.597C84.9821 146.562 90.4677 144.277 85.0109 140.678C74.9577 134.047 57.2086 124.64 57.2086 124.64C69.5386 109.478 56.8618 111.227 63.1755 105.583Z"
        fill="#CE8361"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M45.2387 116.123L49.6415 95.8879L73.1844 94.516C73.1844 94.516 63.8477 116.123 60.8993 119.086C57.9508 122.05 45.2387 116.123 45.2387 116.123Z"
        fill="#FA6C2C"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M35.2822 66.3563L42.4226 62.3189C43.5079 62.463 45.3395 66.1326 44.923 68.0927C44.923 68.0927 37.6824 74.7189 34.9864 73.3942C32.2904 72.0695 19.1475 63.0778 14.6736 54.3988C14.2265 53.6825 14.4108 52.8623 14.7175 52.0336C14.4617 51.3499 14.9294 50.7624 15.3903 50.6493C15.4844 49.7642 16.2459 49.4107 16.7759 49.1887C17.9595 48.6928 19.5078 50.1282 20.4241 51.2645C23.3509 48.7388 26.5454 50.8376 25.2364 51.2308C22.588 52.0261 21.9659 53.6483 21.9443 53.7068C21.9592 53.7123 22.1866 53.9328 25.174 57.6494C28.5093 61.7989 35.2822 66.3563 35.2822 66.3563Z"
        fill="#CE8361"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M63.361 48.0223C54.3512 49.3409 44.4927 56.6113 37.709 60.8614C37.709 60.8614 42.9107 68.1809 44.6999 70.4893L52.0566 61.9825C45.4693 73.0646 41.6763 86.2183 40.7214 92.2765C46.755 90.9592 57.0439 93.3983 60.7193 95.7565C63.6596 97.6431 70.7813 94.8402 74.1662 94.5121C76.6233 85.6668 72.469 68.6466 73.327 61.8747C76.0904 63.6994 79.5579 68.5154 82.7791 69.9529L91.8608 64.223C80.237 52.2433 73.327 52.7425 68.2694 48.0222L63.361 48.0223Z"
        fill="#FEDECF"
      />
      <path
        d="M56.7655 55.355C54.0201 58.7261 54.3462 58.1306 52.0566 61.9825M70.964 58.2166C72.032 59.8974 72.5241 60.5877 73.327 61.8747M73.327 61.8747C76.0904 63.6994 79.5579 68.5154 82.7791 69.9529L91.8608 64.223C80.237 52.2433 73.327 52.7425 68.2694 48.0222L63.361 48.0223C54.3512 49.3409 44.4927 56.6113 37.709 60.8614C37.709 60.8614 42.9107 68.1809 44.6999 70.4893L52.0566 61.9825M73.327 61.8747C72.469 68.6466 76.6233 85.6668 74.1662 94.5121C70.7813 94.8402 63.6596 97.6431 60.7193 95.7565C57.0439 93.3983 46.755 90.9592 40.7214 92.2765C41.6763 86.2183 45.4693 73.0646 52.0566 61.9825"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M20.7121 50.6869C20.2935 51.4723 18.2911 50.8069 16.9738 50.1513C15.6566 49.4957 15.6336 48.8911 15.9419 48.3128C16.2501 47.7345 16.7728 47.4014 18.0901 48.057C19.4073 48.7126 21.1307 49.9015 20.7121 50.6869Z"
        fill="#CE8361"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M19.6223 52.5853C19.2037 53.3707 17.2013 52.7054 15.884 52.0498C14.5667 51.3942 14.5438 50.7896 14.852 50.2112C15.1603 49.6329 15.683 49.2998 17.0002 49.9554C18.3175 50.611 20.0409 51.8 19.6223 52.5853Z"
        fill="#CE8361"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M18.9426 54.5385C18.524 55.3238 16.5216 54.6585 15.2043 54.0029C13.887 53.3473 13.8641 52.7427 14.1723 52.1644C14.4806 51.586 15.0033 51.253 16.3205 51.9086C17.6378 52.5642 19.3612 53.7531 18.9426 54.5385Z"
        fill="#CE8361"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M49.9434 38.5441L50.2812 25.0078L64.8723 25.3719C64.7758 29.2394 66.464 29.8212 66.7951 30.6503C68.1677 29.6878 70.8899 29.5327 71.3858 32.2189C71.8817 34.905 70.0398 36.4121 68.5118 36.5498C67.2358 42.5691 67.16 46.479 67.8266 47.9615C66.3501 50.7391 61.4395 50.1475 59.1688 49.5045L59.2785 45.1096C49.8488 45.1557 49.8645 41.7084 49.9434 38.5441Z"
        fill="#CE8361"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M59.4527 45.1134C60.6858 45.0882 62.7184 44.7724 63.2895 43.7722C63.3137 43.7229 63.3371 43.6747 63.3597 43.6278C63.3396 43.6774 63.3162 43.7256 63.2895 43.7722C62.3893 45.6125 60.45 49.0859 59.3474 49.3325L59.4527 45.1134Z"
        fill="#490210"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M42.0615 24.98C43.0076 32.1805 48.1925 28.9077 50.5202 27.2653L50.5202 25.7322C54.0186 26.5231 54.2359 27.7338 55.4913 26.7683C55.3201 29.2679 62.8847 29.3695 65.359 28.9035C65.359 28.9035 66.1483 30.4443 66.7936 30.6462C67.9363 29.502 70.5845 29.5617 71.2702 31.6896C72.1845 34.5269 69.8113 36.1095 68.5104 36.5461L67.6713 41.9782C68.5312 42.7619 71.3481 44.0753 74.3295 43.0239C77.311 41.9725 76.1371 38.73 75.1775 37.2402C77.2973 36.8826 81.3531 35.0723 80.6181 30.6916C79.883 26.3108 76.0691 25.0077 74.254 24.9038C74.8707 23.6878 75.4229 20.3594 72.6979 16.7733C69.9729 13.1872 64.6595 14.6378 62.3435 15.8113C61.9025 14.6862 59.7882 12.4757 54.8589 12.6341C49.9296 12.7926 48.8381 16.5884 48.9085 18.4665C46.2319 17.6374 41.1154 17.7794 42.0615 24.98Z"
        fill="#F1C8B6"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M50.0883 28.1906L50.0865 25.2422C50.0865 25.2422 53.0349 26.225 56.4748 26.7164C59.4232 27.2078 65.3219 27.2078 65.3219 27.2078C65.3219 28.3872 65.9771 29.501 66.3047 30.1562C66.3047 30.1562 59.425 29.6648 55.4938 29.1734C51.5625 28.682 50.0883 28.1906 50.0883 28.1906Z"
        fill="#CB0935"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M68.2626 22.1212C68.582 21.2613 67.7278 19.8804 65.7729 19.4205C63.9897 19.001 62.6542 20.0172 62.4046 20.4677"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M54.8946 20.29C54.4744 19.495 52.5956 19.0556 50.9796 20.1923C49.5055 21.2291 49.6841 23.0397 49.8504 23.5167"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M51.8168 31.3104C50.9466 30.9369 49.3504 31.4599 49.3118 33.0069"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.619171"
      />
      <path
        d="M56.7312 31.7849C57.263 31.6222 58.3784 32.0371 58.6387 32.8879"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.619171"
      />
      <path
        d="M59.0504 37.5465C59.0153 38.9528 58.1049 40.189 56.8004 40.8919C54.8034 41.9678 52.4407 41.6032 51.1793 40.5757"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.619171"
      />
      <circle
        cx="56.6832"
        cy="33.6807"
        fill="black"
        r="0.52755"
        transform="rotate(1.42954 56.6832 33.6807)"
      />
      <circle
        cx="51.067"
        cy="33.1851"
        fill="black"
        r="0.52755"
        transform="rotate(1.42954 51.067 33.1851)"
      />
      <path
        d="M55.16 35.4001C54.9002 34.4643 53.931 33.9163 52.9952 34.1761C52.0594 34.4359 51.5114 35.4051 51.7712 36.3409C51.9251 36.8952 52.328 37.3135 52.8253 37.5084"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.619171"
      />
      <circle
        cx="2.28605"
        cy="2.28605"
        fill="white"
        r="2.28605"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.52755"
        transform="matrix(-0.999689 -0.0249476 -0.0249476 0.999689 59.7783 30.9961)"
      />
      <path
        d="M48.7121 33.0067C48.6806 34.2689 49.6783 35.3176 50.9404 35.3491C51.3209 35.3586 51.682 35.2746 52.0016 35.118C52.0016 35.118 52.0108 34.7486 52.3731 34.3294C52.7125 33.9367 53.155 33.8212 53.155 33.8212C53.2321 33.6015 53.2767 33.3661 53.2828 33.1208C53.3143 31.8586 52.3167 30.8099 51.0545 30.7784C49.7923 30.7469 48.7436 31.7446 48.7121 33.0067Z"
        fill="white"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.52755"
      />
      <path
        d="M64.3359 31.6377C63.2665 32.1974 60.9168 33.3114 60.073 33.2904"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.52755"
      />
      <circle
        cx="57.067"
        cy="33.919"
        fill="#490210"
        r="0.52755"
        transform="rotate(1.42954 57.067 33.919)"
      />
      <circle
        cx="51.442"
        cy="33.7783"
        fill="#490210"
        r="0.52755"
        transform="rotate(1.42954 51.442 33.7783)"
      />
      <path
        d="M55.498 33.3521L58.6624 33.431"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.52755"
      />
      <path
        d="M49.1689 33.1938L52.3333 33.2728"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.52755"
      />
      <path
        d="M10.8859 108.534C9.13756 92.8761 7.70317 76.1648 12.9425 74.7471C18.1818 73.3295 48.6979 87.3357 48.2669 88.6031C47.6998 90.2707 38.7881 99.9045 38.1643 101.242C37.5176 102.628 19.7497 88.0214 19.2766 88.1044C16.8565 88.5289 15.6565 100.66 17.1635 109.753C17.1635 109.753 12.6343 124.193 10.8859 108.534Z"
        fill="#CE8361"
      />
      <path
        d="M19.2766 88.1044C16.8565 88.5289 15.6565 100.66 17.1635 109.753C17.1635 109.753 12.6343 124.193 10.8859 108.534C9.13756 92.8761 7.70317 76.1648 12.9425 74.7471C18.1818 73.3295 48.6979 87.3357 48.2669 88.6031C47.6998 90.2707 38.7881 99.9045 38.1643 101.242C37.5176 102.628 19.7497 88.0214 19.2766 88.1044ZM19.2766 88.1044C18.3284 87.4392 14.5385 83.8466 14.4705 83.4594"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M9.30235 95.9922L17.6562 95.9922C17.1648 99.923 16.8373 106.967 17.1648 109.751L10.7766 110.242L9.30235 95.9922Z"
        fill="#CB0935"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <path
        d="M33.2441 92.9859L40.3104 83.9737C40.3104 83.9737 53.3694 85.342 57.2921 88.6682C61.2149 91.9945 73.5003 93.8957 73.5003 93.8957C63.9346 105.903 46.2757 104.524 35.3699 101.086C31.9 99.9928 30.9993 95.849 33.2441 92.9859Z"
        fill="#FA6C2C"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
      <circle
        cx="17.1818"
        cy="62.0504"
        fill="white"
        r="10.9282"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.690379"
        transform="rotate(-14.0136 17.1818 62.0504)"
      />
      <path
        d="M22.5654 70.4239C22.5654 69.2447 22.2378 67.6394 22.074 66.9841C21.6809 67.3776 18.6342 66.8201 17.1599 66.4922L14.7029 69.932L17.6513 72.3891C18.8307 72.7822 21.4188 71.2428 22.5654 70.4239Z"
        fill="#490210"
      />
      <path
        d="M7.82331 64.5305L6.34909 64.0391L7.33184 66.987L9.29746 69.9355L10.2803 68.9526L7.82331 64.5305Z"
        fill="#490210"
      />
      <path
        d="M24.5318 55.1953L26.4975 56.6695C27.2838 57.4562 27.8079 60.6006 27.9717 62.0745L25.0232 62.5659C25.0232 61.3865 23.7128 58.7985 23.0576 57.6519L24.5318 55.1953Z"
        fill="#490210"
      />
      <path
        d="M21.092 51.7575C22.2386 52.5766 24.5318 54.5096 24.5318 55.6888M17.1607 51.2656C16.3417 52.0846 14.7037 53.8209 14.7037 54.2141M23.549 57.6539C22.5662 57.3263 19.9125 56.6711 17.1607 56.6711M25.5146 62.0765C24.8594 63.5508 22.7628 67.0889 21.5834 67.482M14.7037 61.0937C15.3589 62.7318 16.8659 66.2044 17.6522 66.9906M10.7725 60.1109C9.78967 61.4213 7.82405 64.2387 7.82405 65.025M15.1951 69.939C14.0485 70.1028 11.3622 70.1356 9.78967 68.9562M18.1436 71.9047C17.9797 72.0685 17.5538 72.4943 17.1607 72.8875M22.0748 70.4304L25.5146 68.9562M10.7725 56.6711L8.31545 55.6888"
        stroke="#490210"
        strokeLinecap="round"
        strokeWidth="0.687968"
      />
      <path
        d="M17.6514 56.6672C17.2582 55.488 15.5219 54.2102 14.7029 53.7188L10.2802 56.1753C9.8871 56.5684 10.1164 59.2875 10.2802 60.5979C10.6733 60.9911 13.3925 61.417 14.7029 61.5808L17.6514 56.6672Z"
        fill="#490210"
      />
      <path
        d="M73.3363 78.4196C73.7832 76.8969 77.946 77.0512 80.7792 77.6301C80.7792 77.6301 84.6251 77.041 86.1872 76.4264C86.1922 76.4267 86.1975 76.4269 86.2028 76.4271C87.1529 76.4406 84.3025 70.9467 83.6978 69.4163C84.1274 69.0194 89.0316 65.941 89.9925 65.2666C90.6915 65.4282 94.8535 71.1698 95.4605 75.6327C96.0675 80.0956 83.6978 83.0386 83.6978 83.0386C80.5065 85.0126 75.475 87.0771 73.7673 85.8476C72.699 85.0785 73.3221 84.7815 74.111 84.6768C73.0758 84.5678 72.5886 83.996 72.5415 83.7632C72.4945 83.5303 72.1923 82.8716 72.9582 82.4374C71.8451 82.1644 71.8433 81.6032 71.9852 81.1198C72.3438 79.898 72.9535 80.5663 75.9777 78.8192C75.047 79.3297 72.7193 80.5212 73.3363 78.4196Z"
        fill="#E29B7B"
      />
      <path
        d="M72.9582 82.4374C71.8451 82.1644 71.8433 81.6032 71.9852 81.1198C72.3438 79.898 72.9535 80.5663 75.9777 78.8192C75.047 79.3297 72.7193 80.5212 73.3363 78.4196C73.7832 76.8969 77.946 77.0512 80.7792 77.6301C80.7792 77.6301 84.6251 77.041 86.1872 76.4264M72.9582 82.4374C72.1923 82.8716 72.4945 83.5303 72.5415 83.7632C72.5886 83.996 73.0758 84.5678 74.111 84.6768M72.9582 82.4374C72.9582 82.4374 72.8521 82.9795 76.5685 81.7424M74.111 84.6768C73.3221 84.7815 72.699 85.0785 73.7673 85.8476C75.475 87.0771 80.5065 85.0126 83.6978 83.0386C83.6978 83.0386 96.0675 80.0956 95.4605 75.6327C94.8535 71.1698 90.6915 65.4282 89.9925 65.2666C89.0316 65.941 84.1274 69.0194 83.6978 69.4163C84.3057 70.9549 87.1835 76.4996 86.1872 76.4264M74.111 84.6768C74.111 84.6768 75.3256 85.0566 77.0059 83.9069M86.1872 76.4264C86.5418 76.4437 87.7649 76.3953 89.7481 75.9422"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.737108"
      />
    </g>
    <defs>
      <clipPath id="clip0_4676_15635">
        <rect fill="white" height="96" rx="4" width="96" />
      </clipPath>
    </defs>
  </svg>
))
