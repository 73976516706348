import NextLink from 'next/link'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { Link } from '@opengovsg/design-system-react'
import { BiInfoCircle } from 'react-icons/bi'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import { useMarkdownComponents } from '@activesg/ui/hooks'
import { useFeatureValue } from '@activesg/ui/libraries'

import { LandingPageSection } from './LandingPageSection'

export const FAQ = () => {
  const value = useFeatureValue('frequently-asked-questions', {})

  const components = useMarkdownComponents({
    styles: {
      list: {
        paddingLeft: '0.25rem',
      },
    },
  })

  return (
    <LandingPageSection
      Icon={BiInfoCircle}
      body={
        <Stack spacing="1rem">
          {value?.sections && (
            <Stack pt="0.5rem">
              <Accordion allowMultiple>
                {value.sections.map(({ question, answer, answers }) => (
                  <AccordionItem key={question} px={0}>
                    <AccordionButton
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      px={0}
                      py="1rem"
                      w="full"
                    >
                      <Text textAlign="left" textStyle="subhead-1">
                        {question}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel
                      pb="1rem"
                      pt="0.25rem"
                      px={0}
                      textStyle="body-1"
                    >
                      {answer && (
                        <ReactMarkdown
                          components={components}
                          remarkPlugins={[gfm]}
                        >
                          {answer}
                        </ReactMarkdown>
                      )}
                      {answers && (
                        <UnorderedList p="0.25rem">
                          {answers.map((answer) => (
                            <ListItem key={answer}>{answer}</ListItem>
                          ))}
                        </UnorderedList>
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Stack>
          )}
          <Text>
            Have other questions?{' '}
            <Link
              as={NextLink}
              href="https://go.gov.sg/activesg-questions"
              target="_blank"
              textColor="base.content.strong"
            >
              See all FAQ
            </Link>
          </Text>
        </Stack>
      }
      title="Frequently asked questions"
    />
  )
}
