import Image from 'next/image'

import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { RestrictedFooter, useIsMobile } from '@opengovsg/design-system-react'

import { useEnv } from '@activesg/ui/hooks'

export function Footer() {
  const { env } = useEnv()

  const isMobile = useIsMobile()

  return (
    <Stack w="full">
      <RestrictedFooter
        // Default to the production site.
        appLink={env.NEXT_PUBLIC_APP_URL ?? 'https://activesg.gov.sg'}
        appName={
          <Flex
            align={isMobile ? 'start' : 'center'}
            direction={isMobile ? 'column' : 'row'}
            gap="2rem"
          >
            <Box>
              <Image
                priority
                alt="ActiveSG logo"
                height={56}
                src="/assets/activesg-logo-full-color.png"
                width={140}
              />
            </Box>

            <Box>
              <Text color="base.content.medium" textStyle="caption-1">
                In partnership with
              </Text>
              <Image
                priority
                alt="ActiveSG logo"
                height={80}
                src="/assets/pa-logo.svg"
                width={80}
              />
            </Box>
          </Flex>
        }
        containerProps={{ bg: 'white' }}
        footerLinks={[
          { href: 'https://go.gov.sg/activesg-questions', label: 'Guide' },
          {
            href: 'https://go.gov.sg/activesg-privacy-statement',
            label: 'Privacy',
          },
          {
            href: 'https://go.gov.sg/activesg-terms-of-use',
            label: 'Terms of Use',
          },
          {
            href: 'https://www.tech.gov.sg/report_vulnerability',
            label: 'Report Vulnerability',
          },
        ]}
      />
    </Stack>
  )
}
