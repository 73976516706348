import { type SVGProps } from 'react'

import { chakra } from '@chakra-ui/react'

export const FreePassSvgr = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    viewBox="0 0 96 96"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5688_178774)">
      <rect fill="#EFADAC" height="96" rx="48" width="96" />
      <path
        d="M58.261 73.3206C44.4148 74.6187 39.3667 71.085 35.5446 69.6427C34.3331 75.1812 30.5687 81.4697 28.8379 83.9216C34.2033 85.8254 45.785 85.1475 50.9052 84.5706C41.9052 99.6283 39.0783 117.672 38.7898 127.191C45.4965 124.811 57.6119 126.109 62.1552 128.273C65.7898 130.003 74.3427 128.705 78.1648 127.84C74.011 125.59 72.7562 110.821 72.7562 101.878C75.3523 100.364 81.8427 99.2822 86.1696 98.2004C83.4004 87.9889 73.1889 75.6283 68.4292 70.7245L58.261 73.3206Z"
        fill="#CB0935"
      />
      <path
        d="M72.7562 101.878C75.3523 100.364 81.8427 99.2822 86.1696 98.2004C83.4004 87.9889 73.1889 75.6283 68.4292 70.7245L58.261 73.3206C44.4148 74.6187 39.3667 71.085 35.5446 69.6427C34.3331 75.1812 30.5687 81.4697 28.8379 83.9216C34.2033 85.8254 45.785 85.1475 50.9052 84.5706M72.7562 101.878C72.7562 110.821 74.011 125.59 78.1648 127.84C74.3427 128.705 65.7898 130.003 62.1552 128.273C57.6119 126.109 45.4965 124.811 38.7898 127.191C39.0783 117.672 41.9052 99.6283 50.9052 84.5706M72.7562 101.878L68.4292 104.042C68.4292 98.2004 67.5639 92.149 67.5639 82.6235M50.9052 84.5706C51.6016 83.3591 53.3588 81.1812 54.1504 80.2437"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.822115"
      />
      <path
        d="M28.4056 83.7052C27.5403 83.5322 27.4334 80.6043 27.794 78.9456C28.6593 79.162 30.1738 79.3783 31.2554 79.5947C30.6064 81.1091 29.271 83.8783 28.4056 83.7052Z"
        fill="#411B00"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.822115"
      />
      <path
        d="M23.7068 62.2907C24.226 65.233 31.2791 68.2763 35.1733 69.6465C35.1733 74.3195 32.1443 77.7955 30.6299 79.1657C28.0338 79.1657 16.5678 73.9734 15.4859 67.9157C14.8772 64.5081 15.6299 43.8291 16.7837 34.5984C14.8366 33.0839 16.1347 29.4061 16.7838 27.6752C16.9872 27.1329 17.7897 26.8679 18.5143 26.8162C18.9181 26.7874 17.2162 19.0277 19.3797 18.8114C21.5431 18.595 20.6778 27.6816 21.7595 27.6753C22.8412 27.6689 22.8412 18.8114 25.6537 20.3258C26.9314 21.0138 24.1393 27.8979 23.7068 33.5166C21.7597 34.8147 23.0578 58.6128 23.7068 62.2907Z"
        fill="#BF7657"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.822115"
      />
      <path
        d="M20.9869 30.1393C20.6625 31.0288 18.4906 30.5728 17.0313 30.0407C15.572 29.5086 15.4634 28.8674 15.7023 28.2123C15.9411 27.5573 16.437 27.1365 17.8963 27.6686C19.3556 28.2007 21.3113 29.2497 20.9869 30.1393Z"
        fill="#BF7657"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.822115"
      />
      <path
        d="M20.5271 32.3763C20.2434 33.1544 18.3438 32.7555 17.0674 32.2901C15.791 31.8247 15.696 31.2639 15.9049 30.6909C16.1138 30.118 16.5475 29.7499 17.8239 30.2153C19.1003 30.6808 20.8108 31.5983 20.5271 32.3763Z"
        fill="#BF7657"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.822115"
      />
      <path
        d="M19.8654 31.6815C20.243 32.7172 22.4327 32.3099 23.892 31.7778C25.3513 31.2457 25.4281 30.5172 25.15 29.7546C24.8719 28.9919 24.3443 28.4838 22.8849 29.016C21.4256 29.5481 19.4877 30.6458 19.8654 31.6815Z"
        fill="#BF7657"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.822115"
      />
      <path
        d="M46.6387 60.5671V43.9085C46.6387 43.9085 48.3694 35.2495 58.9704 35.0332C69.5714 34.8168 70.6531 43.4707 70.6531 43.4707C70.6531 47.6145 69.3412 49.9415 67.7168 51.2028C69.5063 50.1265 72.2156 49.2751 72.8166 52.1296C73.5089 55.4181 69.9319 56.6729 68.0569 56.8892C66.6723 64.3315 68.0569 69.7979 68.9223 71.6008C67.1916 75.0623 61.1339 74.4854 58.3214 73.7642V68.3556C46.7252 68.7017 46.6387 64.4613 46.6387 60.5671Z"
        fill="#BF7657"
        stroke="#411B00"
        strokeLinejoin="round"
        strokeWidth="0.822115"
      />
      <path
        d="M67.625 50.3941V47.0191C56.1525 47.0191 48.9986 44.9422 46.8558 43.9037V47.0191C49.2294 49.096 61.6909 50.1345 67.625 50.3941Z"
        fill="white"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.822115"
      />
      <path
        d="M46.6399 43.471C49.4524 45.2018 62.361 46.7162 67.4091 46.7162L66.9764 51.6922L70.0052 49.9614C71.736 48.7498 72.0245 45.3462 71.9524 42.6058C73.8995 42.8221 78.2264 42.1731 78.2264 47.3653C78.2264 52.1249 71.9524 62.0768 76.4956 67.7019C79.8721 71.8825 88.7553 67.3412 90.3418 65.7547C89.6206 65.8989 83.4187 65.2787 83.4187 60.7787C83.4187 54.5047 91.8562 49.9614 86.8803 42.1731C82.5033 35.3223 75.7024 37.1251 71.087 39.7933C70.3658 38.8558 68.9235 33.3028 60.7024 34.8172C59.6175 35.017 58.5389 33.5189 54.4283 33.5191C50.3178 33.5193 48.7832 36.2175 46.6399 40.0095C45.4688 42.0814 43.6111 42.3893 46.6399 43.471Z"
        fill="#411B00"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.822115"
      />
      <path
        d="M58.5391 68.3554C60.0549 68.2865 62.545 67.8358 63.2168 66.5881C63.245 66.5266 63.2723 66.4667 63.2987 66.4083C63.2755 66.47 63.2482 66.5299 63.2168 66.5881C62.1662 68.8791 59.8875 73.2106 58.5391 73.5477V68.3554Z"
        fill="#411B00"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.822115"
      />
      <path
        d="M49.59 53.4968C49.7021 53.0262 49.9749 52.5017 50.7061 52.5691C51.3873 52.632 51.7458 53.2147 51.683 53.8959"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.649038"
      />
      <path
        d="M56.9913 53.7995C57.0819 53.3243 57.3305 52.7879 58.064 52.822C58.7474 52.8538 59.1321 53.4195 59.1003 54.1029"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.649038"
      />
      <path
        d="M55.8868 55.6005C55.5386 54.4575 54.3297 53.8133 53.1867 54.1615C52.0437 54.5098 51.3995 55.7187 51.7478 56.8616C51.9541 57.5387 52.4624 58.0408 53.0801 58.2652"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.649038"
      />
      <path
        d="M57.2422 49.345C57.2422 48.4471 59.622 48.2307 60.271 49.9615"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.649038"
      />
      <path
        d="M51.6172 49.345C51.6172 48.4471 49.021 48.0144 48.5883 49.9615"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.649038"
      />
      <path
        d="M56.6239 64.2689C59.3134 63.5242 60.4196 59.8766 60.6365 58.1459C58.4247 60.1134 53.1697 62.0511 49.3992 60.1993C49.8574 63.6643 53.9343 65.0136 56.6239 64.2689Z"
        fill="#411B00"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.649038"
      />
      <path
        d="M55.3706 63.0568C53.9889 62.1697 52.6602 62.8884 52.1686 63.3587C55.8132 65.1785 57.6438 63.1885 58.1035 61.966C57.0574 61.1642 55.8457 62.3591 55.3706 63.0568Z"
        fill="white"
      />
      <path
        d="M53.3457 53.423H54.6438"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.649038"
      />
      <path
        d="M53.5616 52.5576C53.5616 53.0122 53.4833 53.4623 53.331 53.8823C53.1788 54.3023 52.9557 54.6839 52.6745 55.0053C52.3932 55.3267 52.0593 55.5817 51.6918 55.7557C51.3244 55.9296 50.9305 56.0191 50.5328 56.0191C50.135 56.0191 49.7411 55.9296 49.3737 55.7557C49.0062 55.5817 48.6723 55.3267 48.391 55.0053C48.1098 54.6839 47.8867 54.3023 47.7345 53.8823C47.5822 53.4623 47.5039 53.0122 47.5039 52.5576L50.5328 52.5576H53.5616Z"
        fill="#411B00"
      />
      <path
        d="M60.4854 52.5576C60.4854 53.0122 60.4071 53.4623 60.2549 53.8823C60.1027 54.3023 59.8796 54.6839 59.5983 55.0053C59.317 55.3267 58.9831 55.5817 58.6157 55.7557C58.2482 55.9296 57.8543 56.0191 57.4566 56.0191C57.0588 56.0191 56.665 55.9296 56.2975 55.7557C55.93 55.5817 55.5961 55.3267 55.3149 55.0053C55.0336 54.6839 54.8105 54.3023 54.6583 53.8823C54.5061 53.4623 54.4277 53.0122 54.4277 52.5576L57.4566 52.5576H60.4854Z"
        fill="#411B00"
      />
      <path
        d="M60.0527 53.423L67.1922 50.8268"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.649038"
      />
      <path
        d="M47.5051 52.9903L46.207 52.5576"
        stroke="#411B00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.649038"
      />
      <path
        d="M58.7551 52.5576L57.457 54.721"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.432692"
      />
      <path
        d="M51.8313 52.5576L50.5332 54.721"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.432692"
      />
    </g>
    <defs>
      <clipPath id="clip0_5688_178774">
        <rect fill="white" height="96" rx="48" width="96" />
      </clipPath>
    </defs>
  </svg>
))
