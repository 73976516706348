import { Flex, SimpleGrid, Stack } from '@chakra-ui/react'
import { useIsMobile } from '@opengovsg/design-system-react'

import {
  PASSES_ROUTES,
  PROGRAMMES_ROUTES,
} from '@activesg/common/routes/member'
import {
  APP_GRID_COLUMN,
  APP_GRID_TEMPLATE_COLUMN,
  APP_RESPONSIVE_PADDING_X,
} from '@activesg/constants'
import { useLoginState } from '@activesg/ui/hooks'
import { type NextPageWithLayout } from '@activesg/ui/types'

import { ErrorBoundary } from '~/components/ErrorBoundary'
import { Footer } from '~/components/Footer'
import { BugReport, FAQ } from '~/components/LandingPage'
import {
  FacilityBookingSvgr,
  MobileFacilityBookingSvgr,
  MobilePassesSvgr,
  MobileProgrammeSvgr,
  PassesSvgr,
  ProgrammeSvgr,
} from '~/components/Svgs/home'
import { ACTIVITIES_LIST } from '~/constants/routes'
import { FeatureCard, MobileFeatureCard } from '~/features/dashboard/components'
import { OverviewSection } from '~/features/dashboard/components/OverviewSection/OverviewSection'
import {
  FreePassBanner,
  GlobalClosureBanner,
} from '~/features/passes/components/FreePass'
import { useIsFreePassEntryDate } from '~/features/passes/useIsFreePassEntryDate'
import { useIsGlobalClosureDate } from '~/features/passes/useIsGlobalClosureDate'
import { AppGrid } from '~/templates/AppGrid'
import { HomeLayout } from '~/templates/layouts/HomeLayout'

const HomePage: NextPageWithLayout = () => {
  const isMobile = useIsMobile()
  const { hasLoginStateFlag } = useLoginState()
  const isFreePassEntryDate = useIsFreePassEntryDate()
  const isGlobalClosureDate = useIsGlobalClosureDate()

  const overview = hasLoginStateFlag ? (
    <ErrorBoundary>
      <OverviewSection />
    </ErrorBoundary>
  ) : undefined
  const banner = isGlobalClosureDate ? (
    <GlobalClosureBanner />
  ) : isFreePassEntryDate ? (
    <FreePassBanner />
  ) : undefined

  return (
    <>
      <Flex flexDir="column" minH={isMobile ? `100vh` : undefined} w="100%">
        {(overview ?? banner) && (
          <AppGrid
            flex={1}
            px={APP_RESPONSIVE_PADDING_X}
            templateColumns={APP_GRID_TEMPLATE_COLUMN}
          >
            <Stack
              _empty={{ display: 'none' }}
              gridColumn={APP_GRID_COLUMN}
              py="1.5rem"
              spacing="1.5rem"
            >
              {banner}
              {overview}
            </Stack>
          </AppGrid>
        )}
        <AppGrid
          bg="base.canvas.brand-subtle"
          flex={1}
          px={APP_RESPONSIVE_PADDING_X}
          templateColumns={APP_GRID_TEMPLATE_COLUMN}
        >
          <Stack
            flexDir="column"
            gridColumn={APP_GRID_COLUMN}
            py="1.5rem"
            spacing="1rem"
          >
            <SimpleGrid columns={{ base: 1, md: 3 }} gap="1rem">
              {isMobile ? (
                <>
                  <MobileFeatureCard
                    description="Buy single and multi-entry passes."
                    href={PASSES_ROUTES.activities().root()}
                    illustration={<MobilePassesSvgr />}
                    title="Buy swim or gym passes"
                  />
                  <MobileFeatureCard
                    description="Book or ballot for slots to play sports."
                    href={ACTIVITIES_LIST}
                    illustration={<MobileFacilityBookingSvgr />}
                    title="Book a facility"
                  />
                  <MobileFeatureCard
                    description="Try a new activity, from yoga to fitness classes."
                    href={PROGRAMMES_ROUTES.activities()}
                    illustration={<MobileProgrammeSvgr />}
                    title="Join a programme"
                  />
                </>
              ) : (
                <>
                  <FeatureCard
                    description="Buy single and multi-entry passes."
                    href={PASSES_ROUTES.activities().root()}
                    illustration={<PassesSvgr />}
                    title="Buy swim or gym passes"
                  />
                  <FeatureCard
                    description="Book or ballot for slots to play sports."
                    href={ACTIVITIES_LIST}
                    illustration={<FacilityBookingSvgr />}
                    title="Book a facility"
                  />
                  <FeatureCard
                    description="Try a new activity, from yoga to fitness classes."
                    href={PROGRAMMES_ROUTES.activities()}
                    illustration={<ProgrammeSvgr />}
                    title="Join a programme"
                  />
                </>
              )}
            </SimpleGrid>
          </Stack>
        </AppGrid>
        <AppGrid
          flex={1}
          px={APP_RESPONSIVE_PADDING_X}
          templateColumns={APP_GRID_TEMPLATE_COLUMN}
        >
          <Stack
            flexDir="column"
            gridColumn={APP_GRID_COLUMN}
            pb="3.5rem"
            pt="2rem"
            spacing="2rem"
          >
            <FAQ />
            <BugReport />
          </Stack>
        </AppGrid>
        <AppGrid
          bg="base.canvas.default"
          borderColor="base.divider.medium"
          borderTopWidth="1px"
          flex={1}
          px={APP_RESPONSIVE_PADDING_X}
          templateColumns={APP_GRID_TEMPLATE_COLUMN}
        >
          <Stack gridColumn={APP_GRID_COLUMN}>
            <Footer />
          </Stack>
        </AppGrid>
      </Flex>
    </>
  )
}

HomePage.getLayout = HomeLayout

export default HomePage
