import { type ReactNode } from 'react'

import Link from 'next/link'

import {
  Box,
  Card,
  CardBody,
  HStack,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react'
import { BiRightArrowAlt } from 'react-icons/bi'

type FeatureCardProps = {
  href: string
  title: string
  description: ReactNode
  illustration: ReactNode
}

export const MobileFeatureCard = ({
  href,
  title,
  description,
  illustration,
}: FeatureCardProps) => {
  return (
    <Card as={Link} href={href}>
      <CardBody
        as="article"
        display="flex"
        flexDirection="row"
        gap="1.5rem"
        justifyContent="space-between"
      >
        <Stack minW={0} spacing="0.5rem">
          <Text color="base.content.brand" overflowWrap="normal" textStyle="h6">
            {title}
          </Text>
          <Text color="base.content.strong" textStyle="body-2">
            {description}
          </Text>
        </Stack>
        <Box>{illustration}</Box>
      </CardBody>
    </Card>
  )
}

export const FeatureCard = ({
  href,
  title,
  description,
  illustration,
}: FeatureCardProps): JSX.Element => {
  return (
    <Card as={Link} href={href}>
      <CardBody as="article" display="flex" flexDirection="column" gap="1rem">
        <Box position="relative">{illustration}</Box>
        <Stack spacing="0.25rem">
          <HStack
            alignItems="center"
            color="base.content.brand"
            spacing="0.25rem"
          >
            <Text overflowWrap="normal" textStyle="subhead-1">
              {title}
            </Text>
            <Icon as={BiRightArrowAlt} size="1.25rem" />
          </HStack>
          <Text color="base.content.strong" textStyle="body-2">
            {description}
          </Text>
        </Stack>
      </CardBody>
    </Card>
  )
}
