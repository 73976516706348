import { HStack, Icon, Stack, Text } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'
import { useAtom } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { BiSolidError } from 'react-icons/bi'

import { APP_GRID_COLUMN } from '@activesg/constants'

import { ErrorBoundary } from '~/components/ErrorBoundary'

import { BookingCard } from './BookingCard'
import {
  useBookingListWithoutProgrammes,
  type MappedBookingItemShape,
} from './useBookingList'

const isActionableItemsExpandedAtom = atomWithHash('actionableExpanded', false)

const ActionableItemListView = ({
  items,
}: {
  items: MappedBookingItemShape[]
}) => {
  const [isExpanded, setIsExpanded] = useAtom(isActionableItemsExpandedAtom)

  if (items.length === 0) {
    return null
  }

  return (
    <Stack
      bgColor="red.100"
      flexDir="column"
      gridColumn={APP_GRID_COLUMN}
      px={0}
      py="1.5rem"
    >
      <HStack justify="space-between">
        <HStack spacing="1rem">
          <Icon as={BiSolidError} />

          <Text textStyle="subhead-3">PAY TO CONFIRM</Text>
        </HStack>
        {items.length > 1 && (
          <Button variant="link" onClick={() => setIsExpanded((prev) => !prev)}>
            {isExpanded ? `See less` : `See all (${items.length})`}
          </Button>
        )}
      </HStack>

      <Stack spacing="1rem">
        {/* TODO: Fix me, have an error card for booking card 
          https://linear.app/ogp/issue/ASG-993/fix-have-an-error-card-for-bookingcard */}
        <ErrorBoundary fallback={<></>}>
          {items.slice(0, isExpanded ? items.length : 1).map((item) => (
            <BookingCard {...item} key={item.bookingId} />
          ))}
        </ErrorBoundary>
      </Stack>
    </Stack>
  )
}

const ActionableItemListWithoutProgrammes = () => {
  const { actionableItems } = useBookingListWithoutProgrammes()
  return <ActionableItemListView items={actionableItems} />
}

export const ActionableItemsList = () => {
  return <ActionableItemListWithoutProgrammes />
}
