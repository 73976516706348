import { useState } from 'react'

import { HStack, Icon, Stack, Text } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'
import { BiHistory } from 'react-icons/bi'

import { BookingCard } from './BookingCard'
import {
  useBookingListWithoutProgrammes,
  type MappedBookingItemShape,
} from './useBookingList'

const PastItemListView = ({ items }: { items: MappedBookingItemShape[] }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  if (items.length === 0) {
    return null
  }

  return (
    <Stack>
      <HStack justify="space-between">
        <HStack spacing="1rem">
          <Icon as={BiHistory} />

          <Text textStyle="subhead-3">Past</Text>
        </HStack>

        {items.length > 1 && (
          // TODO: Move "see all" to its own page, or this is going to be very cluttered
          <Button variant="link" onClick={() => setIsExpanded((prev) => !prev)}>
            {isExpanded ? `See less` : `See all (${items.length})`}
          </Button>
        )}
      </HStack>

      <Stack spacing="1rem">
        {items.slice(0, isExpanded ? items.length : 1).map((item) => (
          <BookingCard {...item} key={item.bookingId} />
        ))}
      </Stack>
    </Stack>
  )
}

const PastItemListWithoutProgrammes = () => {
  const { pastItems } = useBookingListWithoutProgrammes()
  return <PastItemListView items={pastItems} />
}

export const PastItemsList = () => {
  return <PastItemListWithoutProgrammes />
}
