import { type SVGProps } from 'react'

import { chakra } from '@chakra-ui/react'

export const FacilityBookingSvgr = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    viewBox="0 0 280 96"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5753_18732)">
      <rect fill="#FFDA58" height="96" rx="4" width="280" />
      <g opacity="0.2" style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M12.5601 125.647C12.5419 125.774 12.5794 125.903 12.6629 126C12.7464 126.097 12.8678 126.154 12.996 126.155L205.996 127.911C206.123 127.913 206.245 127.859 206.33 127.765C206.415 127.67 206.456 127.543 206.442 127.417C204.213 107.816 194.965 80.3234 174.428 61.5733C153.858 42.7932 122.034 32.8487 74.8202 48.248C74.8026 48.2537 74.7853 48.2606 74.7685 48.2685C65.5366 52.6204 51.4714 61.6657 38.9732 74.762C26.4742 87.8593 15.517 105.035 12.5601 125.647Z"
          fill="#F0C491"
          stroke="#9B7C0F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.888816"
        />
        <path
          d="M95.6486 73.1879C87.3544 69.7385 66.5821 69.7633 57.0166 71.2006C71.8277 35.499 116.384 37.6404 136.811 43.1738L95.6486 73.1879Z"
          fill="#DE8A4E"
          stroke="#9B7C0F"
          strokeWidth="0.888816"
        />
        <path
          d="M99.6716 56.7995C99.8897 56.8065 100.081 56.6542 100.122 56.44C100.164 56.2259 100.044 56.0132 99.839 55.9381C91.0384 52.7103 80.4202 52.278 75.0697 52.7127C74.9896 52.7192 74.9128 52.7473 74.8475 52.794L71.6227 55.096C71.4675 55.2068 71.4003 55.4041 71.4555 55.5865C71.5107 55.7689 71.676 55.8958 71.8665 55.9019L99.6716 56.7995Z"
          fill="#F0C491"
          stroke="#9B7C0F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.888816"
        />
        <path
          d="M110.492 47.2805C110.707 47.2948 110.901 47.153 110.953 46.9441C111.005 46.7351 110.899 46.519 110.703 46.4313C107.738 45.1079 104.181 44.4988 100.778 44.1954C98.2632 43.9712 95.8029 43.9123 93.7119 43.8623C92.9752 43.8447 92.2844 43.8281 91.6531 43.8059C91.5972 43.8039 91.5414 43.8125 91.4887 43.8312L88.3337 44.9514C88.1351 45.022 88.0133 45.2223 88.0422 45.4311C88.0711 45.6399 88.2426 45.7997 88.4529 45.8137L110.492 47.2805Z"
          fill="#F0C491"
          stroke="#9B7C0F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.888816"
        />
        <path
          d="M124.766 81.759C153.462 43.5997 175.691 59.1221 185.133 69.6246C151.068 27.2149 103.178 53.3116 93.1191 75.2299L123.112 81.759H124.766Z"
          fill="#772E05"
        />
        <path
          d="M58.2516 75.3087C74.0623 43.0275 110.898 37.3818 128.037 41.1229C86.3638 28.3154 51.1566 57.8402 38.3203 76.5246L57.7575 75.3087H58.2516Z"
          fill="#772E05"
        />
        <path
          d="M45.7145 88.0984C58.4278 86.1736 86.1635 87.8677 93.2327 89.6516"
          stroke="#9B7C0F"
          strokeLinecap="round"
          strokeWidth="0.888816"
        />
        <path
          d="M43.5309 93.3797C56.2442 91.4549 83.98 93.1489 91.0491 94.9328"
          stroke="#9B7C0F"
          strokeLinecap="round"
          strokeWidth="0.888816"
        />
        <path
          d="M57.6211 74.0138C57.7726 73.9971 57.9051 73.904 57.972 73.767C66.5007 56.3014 80.1808 47.1655 93.7054 42.934C107.245 38.6979 120.615 39.3824 128.464 41.5519C128.701 41.6173 128.945 41.4785 129.011 41.2419C129.076 41.0054 128.937 40.7606 128.701 40.6952C107.442 34.8194 86.065 39.0291 68.4119 47.033C50.7664 55.0334 36.7842 66.8506 30.3105 76.2738C30.2121 76.417 30.2062 76.6045 30.2954 76.7536C30.3846 76.9027 30.5525 76.9862 30.7253 76.9672L57.6211 74.0138Z"
          fill="#F0C491"
          stroke="#9B7C0F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.888816"
        />
        <path
          d="M184.267 68.2787C184.441 68.4478 184.719 68.4463 184.891 68.2753C185.063 68.1043 185.066 67.827 184.898 67.6522C184.406 67.1405 183.87 66.6256 183.294 66.114C158.932 43.1048 138.701 39.8304 123.259 45.1671C107.864 50.4877 97.3817 64.3191 92.3783 75.2211C92.318 75.3524 92.3254 75.5049 92.398 75.6298C92.4707 75.7547 92.5995 75.8365 92.7435 75.8491L92.7822 75.4064C92.7435 75.8491 92.7435 75.8491 92.7436 75.8491L92.744 75.8492L92.7456 75.8493L92.752 75.8499L92.7776 75.8521L92.8786 75.8611C92.9674 75.869 93.0987 75.8808 93.2683 75.8962C93.6074 75.927 94.0994 75.9724 94.7108 76.0305C95.9338 76.1468 97.6339 76.3141 99.5428 76.5182C103.364 76.9268 108.009 77.4818 111.337 78.0693C114.671 78.6579 117.68 79.3104 119.855 79.8158C120.943 80.0685 121.822 80.2843 122.428 80.4368C122.731 80.5131 122.966 80.5735 123.126 80.6149C123.205 80.6355 123.266 80.6514 123.306 80.6621L123.352 80.6741L123.363 80.6772L123.366 80.6779L123.367 80.6781L123.367 80.6781C123.536 80.7234 123.716 80.6647 123.827 80.5283C136.402 64.9902 148.595 59.3356 158.872 58.6529C169.158 57.9697 177.605 62.2606 182.693 66.7697C183.216 67.2634 183.741 67.7664 184.267 68.2787Z"
          fill="#F0C491"
          stroke="#9B7C0F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.888816"
        />
      </g>
      <path
        d="M201.767 66.037C192.17 66.7553 192.17 72.3532 192.17 72.3532C190.375 77.0219 190.97 78.353 190.97 78.353C190.97 78.353 193.369 76.7539 193.77 80.7497C188.173 95.1448 188.122 106.114 186.6 114.378C192.767 113.197 199.7 112.897 203.374 115.39C206.313 117.385 213.945 117.393 217.394 117.148C214.071 114.632 218.96 101.579 218.96 95.9437C218.96 90.3084 220.341 85.8727 219.76 80.7497C218.953 73.6399 221.236 69.7391 220.559 67.555C220.353 66.8898 216.522 66.037 216.522 66.037L207.589 65.2244L201.767 66.037Z"
        fill="#FA6C2C"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.768443"
      />
      <path
        d="M215.163 97.9198C215.925 96.1451 217.28 100.389 220.151 101.044C220.279 101.329 232.856 93.6175 234.136 93.5481C232.937 92.8665 221.687 84.9684 219.277 79.7596C217.28 75.4462 220.045 66.6918 220.919 67.4646C238.081 67.4646 241.923 90.5179 242.948 95.1285C243.973 99.7391 221.236 106.409 221.326 107.25C218.424 109.064 212.791 112.495 212.791 110.49C212.791 109.756 213.403 109.404 213.932 109.187C213.017 109.454 211.57 109.57 211.513 108.54C211.474 107.849 212.429 107.406 213.213 107.136C211.569 107.48 210.515 106.719 211.644 105.752C212.978 104.608 215.246 104.689 217.28 102.614C216.407 102.489 214.401 99.6945 215.163 97.9198Z"
        fill="#F5B896"
      />
      <path
        d="M214.16 106.832C212.025 107.75 210.31 106.895 211.644 105.752C212.978 104.608 215.246 104.689 217.28 102.614C216.407 102.489 214.401 99.6945 215.163 97.9198C215.925 96.1451 217.28 100.389 220.151 101.044C220.285 101.342 234.079 92.8669 234.239 93.5916C234.285 93.8009 221.828 85.2735 219.277 79.7596C217.28 75.4462 220.045 66.6918 220.919 67.4646C238.081 67.4646 241.923 90.5179 242.948 95.1285C243.973 99.7391 221.236 106.409 221.326 107.25C218.424 109.064 212.791 112.495 212.791 110.49C212.791 109.445 214.034 109.173 214.506 108.965M214.16 106.832C213.785 106.994 211.453 107.467 211.513 108.54C211.585 109.84 213.868 109.314 214.506 108.965M214.16 106.832C214.16 106.832 214.887 106.832 217.28 105.154M214.506 108.965C214.506 108.965 215.967 108.581 217.746 107.25"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.768443"
      />
      <path
        d="M198.502 53.6594L200.725 39.1307C200.87 38.1794 203.389 31.8098 212.664 33.0352C221.938 34.2607 221.727 41.9524 221.727 41.9524C221.174 45.5664 219.72 47.4209 218.135 48.3042C219.839 47.6042 222.316 47.2231 222.459 49.7928C222.624 52.7532 219.337 53.3704 217.673 53.309C215.473 59.615 215.951 64.5672 216.465 66.255C214.494 69.0431 208.92 66.7602 206.563 65.756L207.652 62.0105C197.493 60.7655 197.983 57.0558 198.502 53.6594Z"
        fill="#F5B896"
        stroke="#490210"
        strokeLinejoin="round"
        strokeWidth="0.768443"
      />
      <path
        d="M207.871 62.0366C209.202 62.1787 211.435 62.1177 212.187 61.1184C212.22 61.0688 212.251 61.0203 212.282 60.9733C212.253 61.0238 212.222 61.0721 212.187 61.1184C210.96 62.9865 208.298 66.6632 207.077 66.7773L207.871 62.0366Z"
        fill="#490210"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.768443"
      />
      <path
        d="M197.986 29.4354C195.093 27.1392 194.247 29.8934 194.185 31.5575C194.701 35.7511 197.412 38.6839 200.906 40.6911C206.384 40.1773 212.712 41.7247 216.559 43.0855C216.648 45.03 216.838 47.5738 217.449 48.6327C220.512 46.7841 222.16 48.1303 222.601 49.0346C232.858 42.5706 226.005 36.0383 221.296 33.5801C224.068 30.6056 221.104 30.2037 219.276 30.3747C218.901 26.7643 216.894 28.2724 215.937 29.4778C212.279 28.1459 209.618 21.5596 206.758 21.3154C204.471 21.12 203.941 24.1671 203.961 25.715C203.651 25.2171 202.448 24.2477 200.114 24.3542C197.781 24.4608 197.723 27.7861 197.986 29.4354Z"
        fill="#490210"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.768443"
      />
      <path
        d="M199.898 43.4315L200.309 40.7467C205.1 40.1607 212.866 42.1178 216.15 43.1696L216.125 45.9135C208.987 43.4699 202.332 43.0313 199.898 43.4315Z"
        fill="#CB0935"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.768443"
      />
      <path
        d="M206.72 27.8826C207.636 29.8893 210.978 34.6358 217.017 37.5678M204.853 33.7762C205.027 34.3178 205.856 35.5516 207.772 36.1537"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.768443"
      />
      <path
        d="M207.237 50.7441C207.085 49.7008 206.117 48.9777 205.074 49.129C204.03 49.2802 203.307 50.2486 203.458 51.2919C203.548 51.9099 203.924 52.4156 204.433 52.6937"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.645492"
      />
      <path
        d="M208.065 46.6586C208.83 45.9718 210.653 45.9543 211.231 47.5315"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.645492"
      />
      <path
        d="M204.147 45.7278C203.811 45.2269 202.587 44.8129 201.785 45.3516"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.645492"
      />
      <path
        d="M211.662 51.0271C211.62 52.5655 210.453 55.3948 208.155 56.2792C205.857 57.1635 203.7 56.3746 202.654 55.6354"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.645492"
      />
      <path
        d="M209.973 49.3974C209.812 49.7929 209.504 50.2139 208.875 50.0576C208.29 49.9119 208.055 49.3559 208.2 48.7701"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.645492"
      />
      <path
        d="M203.556 48.1459C203.414 48.5483 203.125 48.9829 202.49 48.8553C201.898 48.7364 201.638 48.1917 201.757 47.5999"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.645492"
      />
      <path
        d="M206.277 75.2965C203.433 75.0182 205.027 69.3294 206.564 65.2311L216.195 66C215.17 71.5256 209.832 75.6444 206.277 75.2965Z"
        fill="#F5B896"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.725341"
      />
      <path
        d="M215.732 66.5137L215.876 65.5703L210.15 64.7243L206.792 66.8843L215.732 66.5137Z"
        fill="#F5B896"
      />
      <path
        d="M205.653 67.6344C205.653 67.6344 209.277 65.7294 209.638 64.7202C210 63.711 205.939 65.7622 205.939 65.7622L205.653 67.6344Z"
        fill="#490210"
      />
      <path
        d="M161.178 65.2522C163.339 67.1973 161.178 71.4668 161.178 71.4668C161.178 71.4668 159.828 71.8115 164.513 75.5353C169.198 79.2591 177.995 83.2575 177.995 83.2575C177.995 83.2575 182.362 75.5611 187.371 71.4668C192.865 66.9761 199.985 66.0555 200.786 66.054C201.586 66.0524 201.52 67.3361 200.785 68.8445C199.48 71.5253 195.358 79.2228 193.988 80.8463C193.189 78.0474 189.328 84.8319 183.2 88.6752C180.8 90.1802 177.426 93.1574 174.643 91.7897C171.86 90.4221 158.291 81.1394 153.673 72.1795C153.211 71.44 153.401 70.5933 153.718 69.7377C153.454 69.0319 153.937 68.4254 154.413 68.3086C154.51 67.3949 155.296 67.0299 155.843 66.8007C157.065 66.2887 157.456 67.6777 158.402 68.8507C159.479 67.9214 159.142 66.8506 159.179 65.6515C159.189 65.3152 159.376 63.6294 161.178 65.2522Z"
        fill="#F5B896"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.760969"
      />
      <path
        d="M181.688 42.0245L161.246 64.218"
        stroke="#490210"
        strokeMiterlimit="10"
        strokeWidth="2.36705"
      />
      <path
        d="M149.587 72.1584L159.474 61.4654C160.06 60.8335 161.039 60.7975 161.665 61.3811L164.284 63.8045C164.916 64.3908 164.952 65.3693 164.368 65.9953L154.481 76.6883C153.895 77.3202 152.916 77.3562 152.29 76.7726L149.671 74.3492C149.037 73.7688 149.001 72.7902 149.587 72.1584Z"
        fill="#FE641F"
        stroke="#490210"
        strokeWidth="0.767693"
      />
      <path
        d="M181.926 38.6171L178.998 41.8918C178.998 41.8918 179.729 44.2842 182.396 44.6923L185.443 41.7436L181.935 38.614L181.926 38.6171Z"
        fill="#FE641F"
        stroke="#490210"
        strokeWidth="0.767693"
      />
      <path
        d="M190.848 20.5117C195.251 20.2818 199.666 20.0429 204.069 19.813"
        stroke="#490210"
        strokeMiterlimit="10"
        strokeWidth="0.767693"
      />
      <path
        d="M187.183 24.4007C193.264 24.0402 199.351 23.6679 205.426 23.3046"
        stroke="#490210"
        strokeMiterlimit="10"
        strokeWidth="0.767693"
      />
      <path
        d="M183.924 28.5484C190.792 28.1052 197.669 27.6733 204.542 27.2328"
        stroke="#490210"
        strokeMiterlimit="10"
        strokeWidth="0.767693"
      />
      <path
        d="M183.102 32.6467C189.479 32.353 195.84 32.0656 202.218 31.7719"
        stroke="#490210"
        strokeMiterlimit="10"
        strokeWidth="0.767693"
      />
      <path
        d="M181.726 36.9734C187.503 36.6849 193.277 36.3878 199.06 36.1021"
        stroke="#490210"
        strokeMiterlimit="10"
        strokeWidth="0.767693"
      />
      <path
        d="M197.384 17.6284C193.569 25.2853 190.13 33.1312 187.083 41.1308"
        stroke="#490210"
        strokeMiterlimit="10"
        strokeWidth="0.767693"
      />
      <path
        d="M194.011 17.8124C189.414 24.8843 185.769 32.5915 183.228 40.6347"
        stroke="#490210"
        strokeMiterlimit="10"
        strokeWidth="0.767693"
      />
      <path
        d="M201.664 17.7578C198.439 25.0834 195.199 32.4093 191.974 39.7349"
        stroke="#490210"
        strokeMiterlimit="10"
        strokeWidth="0.767693"
      />
      <path
        d="M205.449 18.8477C202.594 25.4694 199.734 32.0883 196.876 38.7015"
        stroke="#490210"
        strokeMiterlimit="10"
        strokeWidth="0.767693"
      />
      <path
        d="M205.237 17.4762C201.581 13.9287 193.476 16.3396 187.128 22.8739C180.783 29.4024 178.602 37.5772 182.261 41.1188C185.911 44.6779 194.021 42.2553 200.36 35.7241C206.711 29.1984 208.89 21.0294 205.237 17.4762ZM199.092 34.4997C193.879 39.8667 187.267 41.8892 184.302 39.013C181.35 36.1424 183.175 29.4685 188.391 24.1101C193.598 18.7403 200.217 16.7206 203.184 19.5909C206.136 22.4616 204.317 29.1381 199.092 34.4997Z"
        fill="#FE641F"
        stroke="#490210"
        strokeWidth="0.767693"
      />
      <path
        d="M162.002 66.4555C162.642 68.0559 160.936 70.7208 160.403 71.6534L158.403 72.8524C158.537 71.9194 159.603 69.6501 159.603 68.0525C159.603 66.4039 158.707 65.7452 159.603 64.8529C160.403 64.0565 161.202 64.4551 162.002 66.4555Z"
        fill="#F5B896"
      />
      <path
        d="M161.202 71.651C161.735 70.7184 162.642 68.0558 162.002 66.4554C161.202 64.455 160.403 64.0564 159.603 64.8528C158.707 65.7451 159.603 66.4038 159.603 68.0524C159.603 69.65 158.537 71.9193 158.403 72.8523"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.767693"
      />
      <path
        d="M159.109 65.9813C158.677 66.7921 156.61 66.1052 155.25 65.4283C153.89 64.7515 153.866 64.1273 154.185 63.5303C154.503 62.9332 155.042 62.5894 156.402 63.2662C157.762 63.943 159.541 65.1705 159.109 65.9813Z"
        fill="#F5B896"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.760969"
      />
      <path
        d="M157.734 67.575C157.302 68.3858 155.235 67.6989 153.875 67.0221C152.515 66.3453 152.491 65.7211 152.81 65.124C153.128 64.527 153.667 64.1831 155.027 64.86C156.387 65.5368 158.166 66.7642 157.734 67.575Z"
        fill="#F5B896"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.760969"
      />
      <path
        d="M156.61 69.5398C156.178 70.3506 154.111 69.6637 152.751 68.9869C151.391 68.3101 151.367 67.6859 151.686 67.0889C152.004 66.4918 152.543 66.148 153.903 66.8248C155.263 67.5016 157.042 68.729 156.61 69.5398Z"
        fill="#F5B896"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.760969"
      />
      <path
        d="M155.911 71.5633C155.479 72.3741 153.412 71.6872 152.052 71.0104C150.692 70.3336 150.668 69.7094 150.986 69.1123C151.305 68.5153 151.844 68.1714 153.204 68.8482C154.564 69.5251 156.343 70.7525 155.911 71.5633Z"
        fill="#F5B896"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.760969"
      />
      <path
        d="M168.979 24.7725L165.781 22.3672L161.782 29.1707L166.18 30.3702L168.979 24.7725Z"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.767693"
      />
      <line
        stroke="#490210"
        strokeWidth="0.799681"
        x1="164.387"
        x2="168.386"
        y1="23.6337"
        y2="26.0327"
      />
      <line
        stroke="#490210"
        strokeWidth="0.799681"
        x1="162.76"
        x2="167.558"
        y1="25.2127"
        y2="27.6117"
      />
      <line
        stroke="#490210"
        strokeWidth="0.799681"
        x1="162.73"
        x2="166.728"
        y1="27.5975"
        y2="29.1969"
      />
      <path
        d="M171.069 29.2192C170.252 30.836 167.969 31.3288 165.974 30.3166L168.934 24.4612C170.933 25.4686 171.888 27.5975 171.072 29.2144L171.069 29.2192Z"
        fill="#F1EFE7"
        stroke="#490210"
        strokeWidth="0.767693"
      />
      <path
        d="M168.931 24.4614L165.975 30.3086L164.982 29.8069L167.939 23.9598L168.931 24.4614Z"
        fill="#FE641F"
        stroke="#490210"
        strokeWidth="0.767693"
      />
      <path
        d="M165.766 22.321L165.313 22.7908C165.313 22.7908 165.208 22.7112 165.018 22.5749C165.329 22.8401 165.517 22.998 165.517 22.998L164.794 23.7752C164.794 23.7752 164.744 23.7522 164.652 23.7051L163.679 25.6093L163.655 25.5912L162.847 27.5453C162.851 27.549 162.856 27.5526 162.856 27.5526L162.654 28.5958C162.654 28.5958 162.416 28.5433 162.016 28.4445C162.24 28.5162 162.366 28.5587 162.366 28.5587L162.258 29.1991C162.258 29.1991 150.731 28.1488 151.193 27.7077C151.585 27.3277 153.029 26.5916 153.952 26.3564C153.879 26.3237 153.837 26.2996 153.826 26.2839C153.653 25.9953 150.807 24.039 151.303 23.6917C151.576 23.4996 152.509 23.37 153.364 23.3241C152.687 22.527 151.908 21.4188 152.578 21.1802C153.243 20.9464 154.273 20.9276 154.957 20.9482C154.325 20.1322 153.207 18.527 154.021 18.3153C154.435 18.2065 154.976 18.187 155.488 18.2058C155.132 17.6128 154.845 17.0273 154.834 16.7633C154.818 16.1572 158.08 17.2847 158.415 17.2596C158.437 17.2608 158.482 17.2802 158.552 17.3177C158.194 16.4379 157.933 14.8336 158.003 14.2927C158.086 13.6558 165.761 22.3173 165.761 22.3173L165.766 22.321Z"
        fill="#F1EFE7"
        stroke="#490210"
        strokeLinejoin="round"
        strokeWidth="0.767693"
      />
      <path
        d="M165.314 22.7908L165.767 22.321L165.762 22.3173C165.762 22.3173 158.087 13.6558 158.004 14.2927C157.934 14.8336 158.195 16.4379 158.553 17.3177C158.483 17.2802 158.438 17.2608 158.416 17.2596C158.081 17.2847 154.819 16.1572 154.835 16.7633C154.846 17.0273 155.133 17.6128 155.489 18.2058C154.977 18.187 154.436 18.2065 154.022 18.3153C153.208 18.527 154.326 20.1322 154.958 20.9482C154.274 20.9276 153.244 20.9464 152.578 21.1802C151.909 21.4188 152.688 22.527 153.365 23.3241C152.51 23.37 151.577 23.4996 151.304 23.6917C150.808 24.039 153.654 25.9953 153.827 26.2839C153.838 26.2996 153.88 26.3237 153.953 26.3564C153.03 26.5916 151.586 27.3277 151.194 27.7077C150.732 28.1488 162.259 29.1991 162.259 29.1991L162.367 28.5587M165.518 22.998L164.795 23.7752C164.795 23.7752 164.745 23.7522 164.653 23.7051L163.68 25.6093L163.656 25.5912L162.848 27.5453C162.852 27.549 162.857 27.5526 162.857 27.5526L162.655 28.5958"
        stroke="#490210"
        strokeLinejoin="round"
        strokeWidth="0.767693"
      />
      <path
        d="M156.055 21.4262C156.055 21.4262 159.629 23.361 160.665 24.2138C160.665 24.2138 157.501 21.6625 155.473 21.0391C153.444 20.4157 155.534 21.1226 155.534 21.1226L156.059 21.4292"
        fill="#F1EFE7"
      />
      <path
        d="M156.055 21.4262C156.055 21.4262 159.629 23.361 160.665 24.2138C160.665 24.2138 157.501 21.6625 155.473 21.0391C153.444 20.4157 155.534 21.1226 155.534 21.1226L156.059 21.4292"
        stroke="#490210"
        strokeLinejoin="round"
        strokeWidth="0.767693"
      />
      <path
        d="M154.457 23.7661C154.457 23.7661 158.031 25.7008 159.067 26.5537C159.067 26.5537 155.903 24.0023 153.875 23.3789C151.847 22.7556 153.937 23.4624 153.937 23.4624L154.461 23.7691"
        fill="#F1EFE7"
      />
      <path
        d="M154.457 23.7661C154.457 23.7661 158.031 25.7008 159.067 26.5537C159.067 26.5537 155.903 24.0023 153.875 23.3789C151.847 22.7556 153.937 23.4624 153.937 23.4624L154.461 23.7691"
        stroke="#490210"
        strokeLinejoin="round"
        strokeWidth="0.767693"
      />
      <path
        d="M154.623 26.6085C154.623 26.6085 156.784 27.2115 157.506 27.4101C157.506 27.4101 155.319 26.808 154.248 26.5044C153.177 26.2007 154.302 26.5189 154.302 26.5189L154.626 26.6092"
        fill="#F1EFE7"
      />
      <path
        d="M154.623 26.6085C154.623 26.6085 156.784 27.2115 157.506 27.4101C157.506 27.4101 155.319 26.808 154.248 26.5044C153.177 26.2007 154.302 26.5189 154.302 26.5189L154.626 26.6092"
        stroke="#490210"
        strokeLinejoin="round"
        strokeWidth="0.767693"
      />
      <path
        d="M156.375 18.6558L161.339 22.2617C161.339 22.2617 155.66 19.2113 155.584 18.121L156.375 18.6558Z"
        fill="#F1EFE7"
        stroke="#490210"
        strokeLinejoin="round"
        strokeWidth="0.767693"
      />
      <path
        d="M158.187 17.1836C159.12 18.2498 160.186 19.5826 162.185 20.7759"
        stroke="#490210"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.767693"
      />
      <path
        d="M162.564 29.3988C162.564 29.3988 163.489 25.3595 166.018 22.5625L165.238 21.7326C165.238 21.7326 161.971 25.3476 161.479 29.1726L162.56 29.4036L162.564 29.3988Z"
        fill="#FE641F"
        stroke="#490210"
        strokeWidth="0.767693"
      />
    </g>
    <defs>
      <clipPath id="clip0_5753_18732">
        <rect fill="white" height="96" rx="4" width="280" />
      </clipPath>
    </defs>
  </svg>
))
