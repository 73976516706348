import { HStack, Icon, Stack, Text } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'
import { useAtom } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { BiCalendarAlt } from 'react-icons/bi'

import { BookingCard } from './BookingCard'
import {
  useBookingListWithoutProgrammes,
  type MappedBookingItemShape,
} from './useBookingList'

const isUpcomingItemsExpandedAtom = atomWithHash('upcomingExpanded', false)

const UpcomingItemListView = ({
  items,
}: {
  items: MappedBookingItemShape[]
}) => {
  const [isExpanded, setIsExpanded] = useAtom(isUpcomingItemsExpandedAtom)

  if (items.length === 0) {
    return null
  }

  return (
    <Stack>
      <HStack justify="space-between">
        <HStack spacing="1rem">
          <Icon as={BiCalendarAlt} />

          <Text textStyle="subhead-3">UPCOMING</Text>
        </HStack>

        {items.length > 1 && (
          <Button variant="link" onClick={() => setIsExpanded((prev) => !prev)}>
            {isExpanded ? `See less` : `See all (${items.length})`}
          </Button>
        )}
      </HStack>

      <Stack spacing="1rem">
        {items.slice(0, isExpanded ? items.length : 1).map((item) => (
          <BookingCard {...item} key={item.bookingId} />
        ))}
      </Stack>
    </Stack>
  )
}

const UpcomingItemListWithoutProgrammes = () => {
  const { upcomingItems } = useBookingListWithoutProgrammes()
  return <UpcomingItemListView items={upcomingItems} />
}

export const UpcomingItemsList = () => {
  return <UpcomingItemListWithoutProgrammes />
}
