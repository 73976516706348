import { Link } from '@opengovsg/design-system-react'
import { BiBug } from 'react-icons/bi'

import { LandingPageSection } from './LandingPageSection'

export const BugReport = () => {
  return (
    <LandingPageSection
      Icon={BiBug}
      body={
        <>
          <Link
            href="https://go.gov.sg/activesg-bug-report"
            target="_blank"
            textColor="base.content.strong"
          >
            Report
          </Link>{' '}
          any bugs or errors to us.
        </>
      }
      title="Experiencing issues?"
    />
  )
}
